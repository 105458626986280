import {
    PhoneAndroid as AndroidIcon,
    Badge as BadgeIcon,
    PhoneIphone as IphoneIcon,
    Person as UserIcon,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomAvatar } from '~/components/Custom';
import Loading from '~/components/Shared/Loading';
import { pxToRem } from '~/components/theme/typography';
import { formatPhoneNumber } from '~/helpers/format';
import PageStructure from '~/pages/PageStructure';
import { readUser } from '~/redux/actions/users';
import { ReduxStore } from '~/types/redux';
import { UserResponse } from '~/types/users';

const TopContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    })
);

const UserPhotoContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pr: pxToRem(16),
        width: pxToRem(72),
    })
);

const UserMainDataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(72)})`,
    })
);

const BadgeLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(4),
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            mr: pxToRem(8),
            color: theme.palette.secondary.main,
            fontSize: pxToRem(18),
        },
    })
);

const UserInfoContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const UserRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
    })
);

const IconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: pxToRem(32),
        height: pxToRem(32),
        borderRadius: '50%',
    })
);

const RowDescription = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexWrap: 'wrap',
        width: `calc(100% - ${pxToRem(40)})`,
    })
);

const DescriptionText = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        overflowWrap: 'anywhere',
    })
);

const DeleteAccountButton = styled(Button)({
    borderRadius: pxToRem(4),
    fontWeight: 700,
    fontSize: pxToRem(14),
    backgroundColor: '#eee !important',
    color: '#006B75 !important',
    p: `${pxToRem(8)} ${pxToRem(16)}`,
});

type Props = {
    loading: boolean;
    user: UserResponse;
    dispatchReadUser: () => void;
};

const Profile = (props: Props) => {
    const { loading, user, dispatchReadUser } = props;
    const { username, firstName, lastName, phoneNumber, picture, branch } = user || {};
    const PhoneIcon = isIOS ? IphoneIcon : AndroidIcon;

    const userFullName = `${firstName} ${lastName}`;
    const phoneNumberFormatted = phoneNumber ? formatPhoneNumber(`+1${phoneNumber}`) : '';

    const navigate = useNavigate();

    useEffect(() => {
        // If there is no data for the current user, then fetch it.
        if (isEmpty(user)) {
            // Fetch the information from the user.
            dispatchReadUser();
        }
    }, []);

    const goToDeleteAccountPage = () => navigate('/profile/delete-account');

    if (loading) {
        return <Loading />;
    }

    return (
        <PageStructure>
            <TopContainer>
                <UserPhotoContainer>
                    {!!user && (
                        <CustomAvatar
                            firstName={firstName}
                            lastName={lastName}
                            fullName={userFullName}
                            photo={picture}
                            size={64}
                        />
                    )}
                </UserPhotoContainer>
                <UserMainDataContainer>
                    <Typography variant="h6">{userFullName}</Typography>
                    <BadgeLabel>
                        <BadgeIcon />
                        {branch}
                    </BadgeLabel>
                </UserMainDataContainer>
            </TopContainer>
            <UserInfoContainer>
                <UserRow>
                    <IconContainer>
                        <UserIcon />
                    </IconContainer>
                    <RowDescription>
                        <DescriptionText variant="body2">{username}</DescriptionText>
                    </RowDescription>
                </UserRow>
                <UserRow>
                    <IconContainer>
                        <PhoneIcon />
                    </IconContainer>
                    <RowDescription>
                        <DescriptionText variant="body2">{phoneNumberFormatted}</DescriptionText>
                    </RowDescription>
                </UserRow>
                <UserRow>
                    <DeleteAccountButton onClick={goToDeleteAccountPage}>Delete your account</DeleteAccountButton>
                </UserRow>
            </UserInfoContainer>
        </PageStructure>
    );
};

const mapStateToProps = ({ users }: ReduxStore) => {
    const { loading, user } = users;

    return {
        loading,
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadUser: () => dispatch(readUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
