import { DateTime } from 'luxon';

import { Period } from '@allie/operations-common/src/types/shared';

export type SelectableProps = { isSelected: boolean };

export const formatDateXAxisLabel = (period: Period, date: DateTime<true>, isSelected: boolean) => {
    if (period === 'MONTH') {
        let label = date.monthShort.toString(); // e.g. Jan
        if (isSelected) label += `\n${date.toFormat('yyyy')}`; // e.g. 2025
        return label; // e.g. Jan or Jan\n2025
    } else {
        let label = date.day.toString(); // e.g. 1
        if (isSelected) label += `\n${date.toFormat('MMM')}`; // e.g. Jan
        return label; // e.g. 1 or 1\nJan
    }
};
