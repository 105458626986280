import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';
import NewResidentChip from '~/pages/OperationsV2/components/Module/Section/NewResidentChip';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'REGION', 'RISK_AND_COMPLIANCE', 'RESIDENTS_WITH_NEEDS_CHANGES'>[number];

const ITEMS_TO_SHOW = 3;

const Data = ({ items }: { items: Item[] }) => {
    const { palette } = useTheme();

    return (
        <DataContainer>
            <DataTable
                columns={{
                    residentName: {
                        label: 'Resident',
                        weight: 2,
                        renderCell: (value, { row, rowIndex }) => (
                            <Stack
                                sx={{
                                    pb: rowIndex !== ITEMS_TO_SHOW - 1 ? '16px' : '8px', // Get right spacing between rows and the "See more" button
                                    gap: '2px',
                                }}
                            >
                                <Stack
                                    sx={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}
                                >
                                    <Typography>{value}</Typography>
                                    {row.isNewResident && <NewResidentChip />}
                                </Stack>
                                <Typography variant="caption">{row.branchName}</Typography>
                            </Stack>
                        ),
                    },
                    changePercentage: {
                        label: 'Change',
                        alignment: 'right',
                        renderCell: (value) => (
                            <Typography color={value > 0 ? (palette.error[700] as string) : undefined}>
                                {formatPercentage(value, { showSign: 'always', signSeparator: ' ' })}
                            </Typography>
                        ),
                    },
                    callCount: {
                        label: 'Call Count',
                        alignment: 'right',
                    },
                }}
                rows={items}
                takeFirst={ITEMS_TO_SHOW}
                seeMore={() => {}}
                containerProps={{ width: '100%' }}
            />
        </DataContainer>
    );
};

export default Data;
