import React, { useState } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataSelector from '~/pages/OperationsV2/components/Module/Section/DataSelector';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';

type Data = SectionDataType<'REGION', 'RISK_AND_COMPLIANCE', 'INCIDENTS'>;

type SelectorOptionValue = 'falls' | 'onFloor' | 'altercation';

const selectorOptions: { label: string; value: SelectorOptionValue }[] = [
    { label: 'Falls', value: 'falls' },
    { label: 'On Floor', value: 'onFloor' },
    { label: 'Altercation', value: 'altercation' },
];

const dataKeyBySelectorOptionValue: Record<
    SelectorOptionValue,
    keyof Pick<Data, 'fallItems' | 'onFloorItems' | 'altercationItems'>
> = {
    falls: 'fallItems',
    onFloor: 'onFloorItems',
    altercation: 'altercationItems',
};

const Data = ({ data }: { data: Data }) => {
    const [selectedOption, setSelectedOption] = useState<SelectorOptionValue>('falls');

    return (
        <DataContainer>
            <DataSelector
                options={selectorOptions}
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
            />
            <DataTable
                columns={{
                    branchName: {
                        label: 'Community',
                        weight: 2,
                    },
                    count: {
                        label: 'Count',
                        alignment: 'right',
                    },
                }}
                rows={data[dataKeyBySelectorOptionValue[selectedOption]]}
                takeFirst={3}
                seeMore={() => {}}
                containerProps={{ width: '100%' }}
            />
        </DataContainer>
    );
};

export default Data;
