import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';

type Data = DetailsGroupingDataType<'REGION', 'LABOR', 'HIRING_NEEDS', 'ALL'>;

const formatData = (value: number) => `${value} staff`;

const Data = ({ data: { communityItems, regionItems } }: { data: Data }) => {
    return (
        <DataContainer>
            <DataTable
                containerProps={{ sx: { marginBottom: '24px', width: '100%' } }}
                columns={{
                    regionName: { label: 'Region', allowSort: false, weight: 1 },
                    hiresNeededCount: {
                        label: 'FT Hires Needed\n(Estimated)',
                        allowSort: false,
                        weight: 1,
                        renderCell: (value) => formatData(value),
                    },
                }}
                rows={regionItems}
            />
            <DataTable
                containerProps={{ sx: { width: '100%' } }}
                columns={{
                    branchName: { label: 'Community', weight: 1 },
                    hiresNeededCount: {
                        label: 'FT Hires Needed\n(Estimated)',
                        weight: 1,
                        renderCell: (value) => formatData(value),
                    },
                }}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
