import { Stack, Typography } from '@mui/material';
import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'REGION', 'RISK_AND_COMPLIANCE', 'LONGEST_RESPONSE_TIME'>[number];

const ITEMS_TO_SHOW = 5;

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer>
        <DataTable
            columns={{
                residentName: {
                    label: 'Resident',
                    weight: 2,
                    renderCell: (value, { row, rowIndex }) => (
                        <Stack
                            sx={{
                                pb: rowIndex !== ITEMS_TO_SHOW - 1 ? '16px' : '8px', // Get right spacing between rows and the "See more" button
                                gap: '2px',
                            }}
                        >
                            <Typography>{value}</Typography>
                            <Typography variant="caption">{row.branchName}</Typography>
                        </Stack>
                    ),
                },
                responseTimeMs: {
                    label: 'Response Time',
                    alignment: 'right',
                    renderCell: (value) => (value ? formatMsToMinutes(value) : null),
                },
            }}
            rows={items}
            takeFirst={ITEMS_TO_SHOW}
            seeMore={() => {}}
            containerProps={{ width: '100%' }}
        />
    </DataContainer>
);

export default Data;
