import HiringNeeds from './HiringNeeds';
import OvertimeAndAgencyScheduledByBranch from './OvertimeAndAgencyScheduledByBranch';
import OvertimeAndAgencyScheduledByRegion from './OvertimeAndAgencyScheduledByRegion';
import ScheduledVsBudgetedHoursByBranch from './ScheduledVsBudgetedHoursByBranch';
import ScheduledVsBudgetedHoursByRegion from './ScheduledVsBudgetedHoursByRegion';

export default {
    ScheduledVsBudgetedHoursByRegion,
    ScheduledVsBudgetedHoursByBranch,
    OvertimeAndAgencyScheduledByRegion,
    OvertimeAndAgencyScheduledByBranch,
    HiringNeeds,
};
