import { Box, Stack, Typography, alpha, styled, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataSimpleBarChart, {
    DataSimpleBarChartColorMapping,
} from '~/pages/OperationsV2/components/Module/Section/recharts/DataSimpleBarChart';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

type Data = SectionDataType<'REGION', 'RISK_AND_COMPLIANCE', 'SCHEDULED_DOCUMENTATION_RATE'>;
type Item = Data['items'][number];

const renderTooltipLabel = (item: Item) =>
    // e.g. 'Community A: 90%' when hovering
    `${item.branchName}: ${formatPercentage(item.ratePercentage)}`;

const isSelected = (item: Item) => item.isHighlight;

const ChartContainer = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100] as string,
    width: '100%',
    padding: '24px 24px 6px',
    borderRadius: '16px',
    overflowX: 'auto',
}));

const Data = ({ data: { highlightBranch, items } }: { data: Data }) => {
    const { palette } = useTheme();

    const colorMapping: DataSimpleBarChartColorMapping = useMemo(
        () => ({
            selected: [palette.primary[500] as string],
            unselected: [alpha(palette.grey[900], 0.1)],
        }),
        [palette]
    );

    return (
        <DataContainer sx={{ gap: '12px' }}>
            <Stack
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Typography>{highlightBranch.branchName}</Typography>
                <Typography>{formatPercentage(highlightBranch.ratePercentage)}</Typography>
            </Stack>
            <ChartContainer>
                <DataSimpleBarChart
                    height={200}
                    items={items}
                    xAxisDataKey="branchName"
                    yAxisDataKeys={['ratePercentage']}
                    renderTooltipLabel={renderTooltipLabel}
                    isSelected={isSelected}
                    colorMapping={colorMapping}
                />
            </ChartContainer>
        </DataContainer>
    );
};

export default Data;
