import { Stack, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

type Data = DetailsGroupingDataType<'REGION', 'RISK_AND_COMPLIANCE', 'RESIDENTS_WITH_NEEDS_CHANGES', 'ALL'>;

const Data = ({ data: { regionItems, communityItems } }: { data: Data }) => {
    const { palette } = useTheme();

    return (
        <DataContainer>
            <DataTable
                containerProps={{ sx: { marginBottom: '24px' } }}
                columns={{
                    regionName: { label: 'Region', allowSort: false },
                    residentsWithIncreasedCallsNumber: {
                        label: 'Residents with\n+25% increase in calls',
                        allowSort: false,
                        renderCell: (value) => `${value} ${pluralize('resident', value)}`,
                    },
                }}
                rows={regionItems}
            />
            <DataTable
                containerProps={{ sx: { maxWidth: '100%', overflowX: 'scroll' } }}
                columns={{
                    residentName: {
                        label: 'Resident',
                        renderCell: (value, { row }) => (
                            <Stack gap="2px">
                                <Typography>{value}</Typography>
                                <Typography variant="caption">{row.branchName}</Typography>
                            </Stack>
                        ),
                    },
                    callCount: { label: 'Call Count\n(current)' },
                    changePercentage: {
                        label: 'Change\n(last 30 days)',
                        renderCell: (value) => (
                            <Typography color={value > 0 ? (palette.error[700] as string) : undefined}>
                                {formatPercentage(value, { showSign: 'always', signSeparator: ' ' })}
                            </Typography>
                        ),
                    },
                    lastAssessmentDate: {
                        label: 'Last\nAssessment',
                        renderCell: (value) => DateTime.fromISO(value).toFormat('MMM d, yyyy'),
                    },
                    upcomingAssessmentDate: {
                        label: 'Upcoming\nAssessment',
                        renderCell: (value) => DateTime.fromISO(value).toFormat('MMM d, yyyy'),
                    },
                    careLevel: { label: 'Care Level /\nAssessment Points', renderCell: (value) => value },
                    allieHealthNotes: {
                        label: 'AllieHealth Notes',
                        allowSort: false,
                        renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : null),
                    },
                }}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
