import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import AddNotesButton from '~/pages/OperationsV2/components/Module/Details/Notes/AddNotesButton';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = DetailsGroupingDataType<'REGION', 'RISK_AND_COMPLIANCE', 'LONGEST_RESPONSE_TIME', 'ALL'>[number];

const Data = ({ items }: { items: Item[] }) => {
    return (
        <DataContainer>
            <DataTable
                columns={{
                    responseTimeInMs: { label: 'Response Time', renderCell: (value) => formatMsToMinutes(value) },
                    residentName: {
                        label: 'Resident',
                        renderCell: (value, { row }) => (
                            <Stack gap="2px">
                                <Typography>{value}</Typography>
                                <Typography variant="caption">{row.branchName}</Typography>
                            </Stack>
                        ),
                    },
                    staffName: { label: 'Staff' },
                    timestamp: {
                        label: 'Timestamp',
                        renderCell: (value) => DateTime.fromISO(value).toFormat('h:mm a, MMM d'),
                    },
                    managerNotes: {
                        label: 'Manager Notes',
                        allowSort: false,
                        renderCell: (notes) => (notes ? <Notes notes={notes} maxWidth="200px" /> : <AddNotesButton />),
                    },
                }}
                rows={items}
            />
        </DataContainer>
    );
};

export default Data;
