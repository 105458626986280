import { Box, Stack } from '@mui/material';
import times from 'lodash/times';
import React, { ComponentProps } from 'react';

type Variant = 'selected' | 'unselected';

type ColorMapping = { [key in Variant]: string[] };

const colorsBySegmentCount: ColorMapping[] = [
    {
        selected: ['#BDDDF6', '#8EB8ED', '#63A4A8', '#127880'],
        unselected: [
            'rgba(37, 67, 89, 0.10)',
            'rgba(37, 67, 89, 0.20)',
            'rgba(37, 67, 89, 0.50)',
            'rgba(37, 67, 89, 1.00)',
        ],
    },
    {
        selected: ['#BDDDF6', '#8EB8ED', '#1D5EB8', '#63A4A8', '#127880'],
        unselected: [
            'rgba(37, 67, 89, 0.10)',
            'rgba(37, 67, 89, 0.20)',
            'rgba(37, 67, 89, 0.30)',
            'rgba(37, 67, 89, 0.60)',
            'rgba(37, 67, 89, 1.00)',
        ],
    },
    {
        selected: ['#BDDDF6', '#8EB8ED', '#1D5EB8', '#BEE1E5', '#63A4A8', '#127880'],
        unselected: [
            'rgba(37, 67, 89, 0.10)',
            'rgba(37, 67, 89, 0.20)',
            'rgba(37, 67, 89, 0.30)',
            'rgba(37, 67, 89, 0.40)',
            'rgba(37, 67, 89, 0.50)',
            'rgba(37, 67, 89, 1.00)',
        ],
    },
    {
        selected: ['#BDDDF6', '#8EB8ED', '#1D5EB8', '#63A4A8', '#127880', '#FFB669', '#D98429'],
        unselected: [
            'rgba(37, 67, 89, 0.10)',
            'rgba(37, 67, 89, 0.20)',
            'rgba(37, 67, 89, 0.30)',
            'rgba(37, 67, 89, 0.40)',
            'rgba(37, 67, 89, 0.50)',
            'rgba(37, 67, 89, 0.60)',
            'rgba(37, 67, 89, 1.00)',
        ],
    },
    {
        selected: ['#BDDDF6', '#8EB8ED', '#1D5EB8', '#BEE1E5', '#63A4A8', '#127880', '#FFB669', '#D98429'],
        unselected: [
            'rgba(37, 67, 89, 0.10)',
            'rgba(37, 67, 89, 0.20)',
            'rgba(37, 67, 89, 0.30)',
            'rgba(37, 67, 89, 0.40)',
            'rgba(37, 67, 89, 0.50)',
            'rgba(37, 67, 89, 0.60)',
            'rgba(37, 67, 89, 0.70)',
            'rgba(37, 67, 89, 1.00)',
        ],
    },
    {
        selected: ['#BDDDF6', '#8EB8ED', '#1D5EB8', '#63A4A8', '#127880', '#FFB669', '#D98429', '#FF8888', '#EB6767'],
        unselected: [
            'rgba(37, 67, 89, 0.10)',
            'rgba(37, 67, 89, 0.20)',
            'rgba(37, 67, 89, 0.30)',
            'rgba(37, 67, 89, 0.40)',
            'rgba(37, 67, 89, 0.50)',
            'rgba(37, 67, 89, 0.60)',
            'rgba(37, 67, 89, 0.70)',
            'rgba(37, 67, 89, 0.80)',
            'rgba(37, 67, 89, 1.00)',
        ],
    },
    {
        selected: [
            '#BDDDF6',
            '#8EB8ED',
            '#1D5EB8',
            '#BEE1E5',
            '#63A4A8',
            '#127880',
            '#FFB669',
            '#D98429',
            '#FF8888',
            '#EB6767',
        ],
        unselected: [
            'rgba(37, 67, 89, 0.10)',
            'rgba(37, 67, 89, 0.20)',
            'rgba(37, 67, 89, 0.30)',
            'rgba(37, 67, 89, 0.40)',
            'rgba(37, 67, 89, 0.50)',
            'rgba(37, 67, 89, 0.60)',
            'rgba(37, 67, 89, 0.70)',
            'rgba(37, 67, 89, 0.80)',
            'rgba(37, 67, 89, 0.90)',
            'rgba(37, 67, 89, 1.00)',
        ],
    },
    {
        selected: [
            '#BDDDF6',
            '#8EB8ED',
            '#1D5EB8',
            '#BEE1E5',
            '#63A4A8',
            '#127880',
            '#FFDEBC',
            '#FFB669',
            '#D98429',
            '#FF8888',
            '#EB6767',
        ],
        unselected: [
            'rgba(37, 67, 89, 0.10)',
            'rgba(37, 67, 89, 0.20)',
            'rgba(37, 67, 89, 0.30)',
            'rgba(37, 67, 89, 0.35)',
            'rgba(37, 67, 89, 0.40)',
            'rgba(37, 67, 89, 0.50)',
            'rgba(37, 67, 89, 0.60)',
            'rgba(37, 67, 89, 0.70)',
            'rgba(37, 67, 89, 0.80)',
            'rgba(37, 67, 89, 0.90)',
            'rgba(37, 67, 89, 1.00)',
        ],
    },
    {
        selected: [
            '#BDDDF6',
            '#8EB8ED',
            '#1D5EB8',
            '#BEE1E5',
            '#63A4A8',
            '#127880',
            '#FFDEBC',
            '#FFB669',
            '#D98429',
            '#FFBDBD',
            '#FF8888',
            '#EB6767',
        ],
        unselected: [
            'rgba(37, 67, 89, 0.10)',
            'rgba(37, 67, 89, 0.20)',
            'rgba(37, 67, 89, 0.30)',
            'rgba(37, 67, 89, 0.35)',
            'rgba(37, 67, 89, 0.40)',
            'rgba(37, 67, 89, 0.45)',
            'rgba(37, 67, 89, 0.50)',
            'rgba(37, 67, 89, 0.60)',
            'rgba(37, 67, 89, 0.70)',
            'rgba(37, 67, 89, 0.80)',
            'rgba(37, 67, 89, 0.90)',
            'rgba(37, 67, 89, 1.00)',
        ],
    },
];

const MIN_SEGMENT_COUNT = colorsBySegmentCount[0].selected.length;
const MAX_SEGMENT_COUNT = colorsBySegmentCount.length + MIN_SEGMENT_COUNT - 1;

export const getSegmentedColorMapping = (segmentCount: number): ColorMapping => {
    const index = Math.min(segmentCount - MIN_SEGMENT_COUNT, colorsBySegmentCount.length) - 1;
    const colorMapping = colorsBySegmentCount[index];
    const missingSegmentCount = Math.max(0, segmentCount - MAX_SEGMENT_COUNT);

    return {
        selected: [...colorMapping.selected, ...times(missingSegmentCount, () => colorMapping.selected.at(-1)!)],
        unselected: [...colorMapping.unselected, ...times(missingSegmentCount, () => colorMapping.unselected.at(-1)!)],
    };
};

type DataHorizontalSegmentedBarProps = {
    variant: Variant;
    items: { weight: number }[];
};

const DataHorizontalSegmentedBar = ({
    variant,
    items,
    sx,
    ...props
}: DataHorizontalSegmentedBarProps & ComponentProps<typeof Stack>) => {
    const colorMapping = getSegmentedColorMapping(items.length);

    return (
        <Stack
            {...props}
            sx={{
                flexDirection: 'row',
                borderRadius: '8px',
                overflow: 'hidden',
                ...sx,
            }}
        >
            {items.map((item, index) => (
                <Box
                    key={index}
                    sx={{
                        flex: item.weight,
                        backgroundColor: colorMapping[variant][index],
                        height: '100%',
                        borderLeft: index >= (variant === 'selected' ? MAX_SEGMENT_COUNT : 0) ? '1px solid' : 'none',
                        borderColor: 'white',
                    }}
                />
            ))}
        </Stack>
    );
};

export default DataHorizontalSegmentedBar;
