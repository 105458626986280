import { Stack, Typography } from '@mui/material';
import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';
import NewResidentChip from '~/pages/OperationsV2/components/Module/Section/NewResidentChip';

type Item = SectionDataType<'REGION', 'CARE_REVENUE', 'CHANGE_OF_CONDITION'>['items'][number];

const ITEMS_TO_SHOW = 3;

const Data = ({ items }: { items: Item[] }) => {
    return (
        <DataContainer>
            <DataTable
                columns={{
                    residentName: {
                        label: 'Resident',
                        weight: 3,
                        renderCell: (value, { row, rowIndex }) => (
                            <Stack
                                sx={{
                                    pb: rowIndex !== ITEMS_TO_SHOW - 1 ? '16px' : '8px', // Get right spacing between rows and the "See more" button
                                    gap: '2px',
                                }}
                            >
                                <Stack
                                    sx={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}
                                >
                                    <Typography>{value}</Typography>
                                    {row.isNewResident && <NewResidentChip />}
                                </Stack>
                                <Typography variant="caption">{row.branchName}</Typography>
                            </Stack>
                        ),
                    },
                    cocCount: {
                        label: 'Total\nChanges',
                        alignment: 'right',
                    },
                    careType: {
                        label: 'Care\nType',
                        alignment: 'right',
                    },
                }}
                rows={items}
                takeFirst={ITEMS_TO_SHOW}
                seeMore={() => {}}
                containerProps={{ width: '100%' }}
            />
        </DataContainer>
    );
};

export default Data;
