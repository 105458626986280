import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { DocumentedCallDetailsResponse } from '~/types/call/call';

export type RemappedDocumentedCallDetails = ReturnType<typeof remapCallDetails>;

export const useGetDocumentedCallDetails = (callId?: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<DocumentedCallDetailsResponse>(
            `/e-call/${branchId}/care/documented-calls/${callId}`,
            { headers: { Authorization: token } }
        );

        return remapCallDetails(data.response);
    }, [token, branchId, callId]);

    return useErrorHandledQuery({
        queryKey: ['documented-call-details', branchId, callId],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId && !!callId,
    });
};

const remapCallDetails = ({
    triggeredAt,
    attendedAt,
    resident,
    attendedByUser,
    ...call
}: DocumentedCallDetailsResponse['response']) => ({
    ...call,
    triggeredAt: fromISO(triggeredAt),
    attendedAt: fromISO(attendedAt),
    resident: resident ? remapPerson(resident) : null,
    attendedByUser: remapPerson(attendedByUser),
});

const remapPerson = <T extends { firstName: string; lastName: string }>(person: T) => ({
    ...person,
    name: `${person.firstName} ${person.lastName}`,
});

const fromISO = (date: string) => DateTime.fromISO(date, { setZone: true }) as DateTime<true>;
