import ChangeOfCondition from './ChangeOfCondition';
import DistributionOfLevels from './DistributionOfLevels';
import OnDemandCare from './OnDemandCare';
import PotentialCareLevelIncrease from './PotentialCareLevelIncrease';
import ResidentsWithNeedsChanges from './ResidentsWithNeedsChanges';

export default {
    DistributionOfLevels,
    ResidentsWithNeedsChanges,
    OnDemandCare,
    ChangeOfCondition,
    PotentialCareLevelIncrease,
};
