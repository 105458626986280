import { Box, ThemeProvider } from '@mui/material';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import theme from '~/pages/OperationsV2/theme';
import { usePermissions } from '~/permissions/utils';
import { ResidentTasks } from '~/types/dailyTasks.d';

import { ResidentRows as ConfirmedResidentRows } from '../confirmedTaskListComponents/ResidentRows';

import { CallsTab } from './CallsTab';
import { searchQueryAtom } from './atom';
import DataSelector from './components/DataSelector';
import { SearchField } from './components/SearchField';

enum Tabs {
    Calls = 'Calls',
    AllCare = 'All Care',
}

interface ButtonTabsProps {
    selectedTab: Tabs;
    onTabChange: (tab: Tabs) => void;
}

const ButtonTabs = ({ selectedTab, onTabChange }: ButtonTabsProps) => {
    const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom);

    return (
        <ThemeProvider theme={theme}>
            <Box padding="24px" paddingBottom="12px">
                <DataSelector
                    options={[
                        { label: 'Calls', value: Tabs.Calls },
                        { label: 'All Care', value: Tabs.AllCare },
                    ]}
                    value={selectedTab}
                    onChange={onTabChange}
                />
            </Box>
            {selectedTab === Tabs.Calls && ( // TODO: Disable while loading documented calls (AH-1753)
                <Box padding="0 24px 24px 24px">
                    <SearchField searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                </Box>
            )}
        </ThemeProvider>
    );
};

interface ConfirmedTaskListV2Props {
    shiftDay: DateTime;
    shiftId: number;
    residentTasksList: ResidentTasks[];
    branchId: number;
}

// TODO - turn this on after integration is complete for demo consistency
const shouldUseNewDoneTab = true;

export const ConfirmedTaskListV2 = (props: ConfirmedTaskListV2Props) => {
    const { shiftDay, shiftId, residentTasksList, branchId } = props;
    const hasPermission = usePermissions();
    const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Calls);

    const hasECallAccess = hasPermission('Community', 'ecall-caregiver') && shouldUseNewDoneTab;

    if (!hasECallAccess) {
        return <ConfirmedResidentRows residentTasksList={residentTasksList} branchId={branchId} />;
    }

    return (
        <>
            <ButtonTabs selectedTab={selectedTab} onTabChange={setSelectedTab} />

            {selectedTab === Tabs.Calls && <CallsTab shiftDay={shiftDay} shiftId={shiftId} />}
            {selectedTab === Tabs.AllCare && (
                <ConfirmedResidentRows residentTasksList={residentTasksList} branchId={branchId} />
            )}
        </>
    );
};
