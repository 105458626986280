import { useTheme } from '@mui/material/styles';
import { SlidersHorizontal } from '@phosphor-icons/react';
import { useAtom } from 'jotai';
import React from 'react';

import { isFiltersExpandedAtom } from '~/pages/Home/atom';
import { ChipContainer } from '~/pages/Home/components/HeaderV2/components/HeaderChip';
import useHeaderV2 from '~/pages/Home/components/HeaderV2/hooks/useHeaderV2';
import { useRouteHandle } from '~/router';

const ExpandFiltersToggle = () => {
    const isHeaderV2Enabled = useHeaderV2();
    const { hasExpandFiltersToggle } = useRouteHandle();
    const { palette } = useTheme();
    const [isFiltersExpanded, setIsFiltersExpanded] = useAtom(isFiltersExpandedAtom);

    if (!isHeaderV2Enabled || !hasExpandFiltersToggle) {
        return null;
    }

    const expandedBgColor = palette.primary[300] as string;
    const defaultBgColor = palette.primary[100] as string;

    return (
        <ChipContainer
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            sx={{
                ...(isFiltersExpanded && { backgroundColor: expandedBgColor }),
                borderRadius: '16px',
                flex: 'unset',
                height: '32px',
                minWidth: '32px',
                padding: 0,
                '&:hover': {
                    backgroundColor: isFiltersExpanded ? expandedBgColor : defaultBgColor,
                },
            }}
        >
            <SlidersHorizontal size={23} weight="bold" style={{ transform: 'rotate(90deg)' }} />
        </ChipContainer>
    );
};

export default ExpandFiltersToggle;
