import { Typography, useTheme } from '@mui/material';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import { formatSign } from '~/pages/OperationsV2/utils/format';

type Data = DetailsGroupingDataType<'REGION', 'LABOR', 'SCHEDULED_VS_BUDGETED_HOURS', 'ALL'>;

const formatHours = (value: number | string) => (typeof value === 'number' ? value.toFixed(0) : `${value} hr`);

const ScheduledMinusBudgetedHoursData = ({ value }: { value: number }) => {
    const { palette } = useTheme();

    const color = value > 0 ? palette.error.dark : value < 0 ? palette.success.main : palette.text.primary;

    const valueWithSign = formatSign(+value.toFixed(0), { showSign: 'always' });

    return <Typography color={color}>{formatHours(valueWithSign)}</Typography>;
};

const Data = ({ data: { regionItems, communityItems } }: { data: Data }) => {
    return (
        <DataContainer>
            <DataTable
                containerProps={{ sx: { marginBottom: '24px', maxWidth: '100%', overflowX: 'auto' } }}
                columns={{
                    regionName: { label: 'Region' },
                    scheduledMinusBudgetedHours: {
                        label: 'Scheduled\nminus Budgeted',
                        allowSort: false,
                        renderCell: (value) => <ScheduledMinusBudgetedHoursData value={value} />,
                    },
                    scheduledHours: {
                        label: 'Scheduled',
                        allowSort: false,
                        renderCell: (value) => formatHours(value),
                    },
                    budgetedHours: {
                        label: 'Budgeted',
                        allowSort: false,
                        renderCell: (value) => formatHours(value),
                    },
                }}
                rows={regionItems}
            />
            <DataTable
                containerProps={{ sx: { maxWidth: '100%', overflowX: 'auto' } }}
                columns={{
                    branchName: { label: 'Community' },
                    scheduledMinusBudgetedHours: {
                        label: 'Scheduled\nminus Budgeted',
                        renderCell: (value) => <ScheduledMinusBudgetedHoursData value={value} />,
                    },
                    scheduledHours: { label: 'Scheduled', renderCell: (value) => formatHours(value) },
                    budgetedHours: { label: 'Budgeted', renderCell: (value) => formatHours(value) },
                }}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
