import React, { useCallback } from 'react';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

import { ChartState, DotProps, Item, TickProps } from '~/pages/OperationsV2/utils/recharts';
import { StringKeyOf } from '~/pages/OperationsV2/utils/types';

import DataMultiLineChartActiveDot from './DataMultiLineChartActiveDot';
import DataMultiLineChartDot from './DataMultiLineChartDot';
import DataMultiLineChartXAxisTick from './DataMultiLineChartXAxisTick';

// Colors for each bar, left to right
export type DataMultiLineChartColorMapping = { dot: string; line: string }[];

type DataMultiLineChartProps<T extends Item, TX extends StringKeyOf<T>> = {
    width: number;
    height: number;
    items: T[];
    xAxisDataKey: TX;
    yAxisDataKeys: StringKeyOf<T>[];
    renderXAxisLabel: (item: T) => string;
    colorMapping: DataMultiLineChartColorMapping;
    isSelected: (item: T) => boolean;
    isFocused: (item: T) => boolean;
    onFocus: (item: T) => void;
};

const DataMultiLineChart = <T extends Item, TX extends StringKeyOf<T>>({
    width,
    height,
    items,
    xAxisDataKey,
    yAxisDataKeys,
    renderXAxisLabel,
    colorMapping,
    isSelected,
    isFocused,
    onFocus,
}: DataMultiLineChartProps<T, TX>) => {
    const onChartClick = useCallback(
        (chartState: ChartState<T>) => {
            const { activePayload } = chartState;
            const item = activePayload?.[0]?.payload;

            if (item) onFocus(item);
        },
        [onFocus]
    );

    return (
        <LineChart
            width={width}
            height={height}
            data={items}
            onClick={onChartClick}
            style={{
                cursor: 'pointer',
                userSelect: 'none',
            }}
        >
            {yAxisDataKeys.map((key, keyIndex) => {
                const colors = colorMapping[keyIndex];
                return (
                    <Line
                        key={key}
                        type="linear"
                        dataKey={key}
                        stroke={colors.line}
                        strokeWidth={2}
                        dot={(props: DotProps<T>) => (
                            <DataMultiLineChartDot
                                {...props}
                                chartHeight={height}
                                dataKey={key}
                                keyIndex={keyIndex}
                                color={colors.dot}
                                isSelected={isSelected}
                                isFocused={isFocused}
                            />
                        )}
                        activeDot={(props: DotProps<T>) => (
                            <DataMultiLineChartActiveDot
                                {...props}
                                chartHeight={height}
                                dataKey={key}
                                keyIndex={keyIndex}
                                color={colors.dot}
                                isSelected={isSelected}
                                isFocused={isFocused}
                            />
                        )}
                    />
                );
            })}
            <Tooltip
                // Just so it renders dots when hovering over the chart
                content={() => null}
                cursor={false}
                isAnimationActive={false}
            />
            <XAxis
                dataKey={xAxisDataKey}
                interval={0} // Show all ticks
                padding={{ left: 24, right: 24 }}
                axisLine={false}
                tickLine={false}
                tick={(props: TickProps<T, typeof xAxisDataKey>) => (
                    <DataMultiLineChartXAxisTick
                        {...props}
                        chartHeight={height}
                        items={items}
                        dataKey={xAxisDataKey}
                        isSelected={isSelected}
                        renderLabel={renderXAxisLabel}
                    />
                )}
            />
            <YAxis padding={{ top: 16, bottom: 24 }} domain={['dataMin', 'dataMax']} hide />
        </LineChart>
    );
};

export default DataMultiLineChart;
