import React, { useState } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataSelector from '~/pages/OperationsV2/components/Module/Section/DataSelector';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';

type Data = SectionDataType<'REGION', 'RISK_AND_COMPLIANCE', 'MEDICATION'>;

type SelectorOptionValue = 'unavailable' | 'refused';

const selectorOptions: { label: string; value: SelectorOptionValue }[] = [
    { label: 'Unavailable', value: 'unavailable' },
    { label: 'Refused', value: 'refused' },
];

const dataKeyBySelectorOptionValue: Record<SelectorOptionValue, keyof Pick<Data, 'unavailableItems' | 'refusedItems'>> =
    {
        unavailable: 'unavailableItems',
        refused: 'refusedItems',
    };

const Data = ({ data }: { data: Data }) => {
    const [selectedOption, setSelectedOption] = useState<SelectorOptionValue>('unavailable');

    return (
        <DataContainer>
            <DataSelector
                options={selectorOptions}
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
            />
            <DataTable
                columns={{
                    branchName: {
                        label: 'Community',
                        weight: 2,
                    },
                    count: {
                        label: 'Count',
                        alignment: 'right',
                    },
                }}
                rows={data[dataKeyBySelectorOptionValue[selectedOption]]}
                takeFirst={3}
                seeMore={() => {}}
                containerProps={{ width: '100%' }}
            />
        </DataContainer>
    );
};

export default Data;
