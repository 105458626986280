import { CALL_STATUS, CALL_TASK_FLAG } from '@allie/utils/src/constants/ecall/call.constants';
import { DEVICE_LOCATION_TYPE } from '@allie/utils/src/constants/ecall/device.constants';

import { BaseResponse } from '../shared';

export enum UnclaimedLevel {
    Level1 = 1,
    Level2 = 2,
    Level3 = 3,
}

export interface ResidentCall {
    id: number;
    firstName: string;
    lastName: string;
    photo: string | null;
    roomNumber: string;
}

export interface UserData {
    id: number;
    firstName: string;
    lastName: string;
}

export interface DeviceLocation {
    id: number;
    name: string;
    type: DEVICE_LOCATION_TYPE;
    zoneName: string | null;
    roomNumber: string | null;
}

export interface OngoingCall {
    id: number;
    /** number: 1, 2, or 3 */
    level: UnclaimedLevel;
    status: CALL_STATUS;
    /** ISO string */
    triggeredAt: string;
    /** ISO string */
    claimedAt: string | null;
    /** ISO string */
    attendedAt: string | null;
    /* ISO string */
    completedAt: string | null;
    resident?: ResidentCall;
    deviceLocation?: DeviceLocation | null;
    claimedByUser: UserData | null;
    attendedByUser: UserData | null;
    completedByUser: UserData | null;
}

export type OngoingCallsResponse = BaseResponse<OngoingCall[]>;

export interface UndocumentedCall {
    id: number;
    /** ISO string */
    triggeredAt: string;
    resident?: ResidentCall;
    deviceLocation?: DeviceLocation;
}

export type UndocumentedCallsResponse = BaseResponse<UndocumentedCall[]>;

export type PUBLIC_LOCATION_TYPE = Exclude<DEVICE_LOCATION_TYPE, DEVICE_LOCATION_TYPE.PRIVATE_ROOM>;

export interface DocumentedCall {
    id: number;
    triggeredAt: string;
    attendedAt: string;
    resident?: ResidentCall;
    deviceLocation?: DeviceLocation;
    attendedByUser: UserData;
    prnCategories: string[];
}

export type DocumentedCallsResponse = BaseResponse<DocumentedCall[]>;

export interface DocumentedCallTask {
    id: number;
    flag: CALL_TASK_FLAG;
    category: string;
    subcategory: string;
    assistLevel?: string;
    activities?: string[];
    notes?: string;
}

export interface DocumentedCallDetails {
    id: number;
    triggeredAt: string;
    attendedAt: string;
    resident?: ResidentCall;
    deviceLocation?: DeviceLocation;
    attendedByUser: UserData;
    tasks: DocumentedCallTask[];
}

export type DocumentedCallDetailsResponse = BaseResponse<DocumentedCallDetails>;
