import { ThemeOptions } from '@mui/material';

import { MuiButton } from './MuiButton';
import { MuiIconButton } from './MuiIconButton';
import { MuiMenu } from './MuiMenu';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiTextField } from './MuiTextField';

type ThemeComponents = NonNullable<ThemeOptions['components']>;
export type ComponentOverride<T extends keyof ThemeComponents> = ThemeComponents[T];

const components: ThemeComponents = {
    MuiButton,
    MuiIconButton,
    MuiMenu,
    MuiMenuItem,
    MuiTextField,
};

export default components;
