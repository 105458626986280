import { Button, Stack } from '@mui/material';
import React from 'react';

const DataSelector = <T extends string>({
    options,
    value,
    onChange,
}: {
    options: { label: string; value: T }[];
    value: T;
    onChange: (value: T) => void;
}) => (
    <Stack
        sx={{
            flexDirection: 'row',
            gap: '4px',
            width: '100%',
        }}
    >
        {options.map((option) => (
            <Button
                key={option.value}
                variant={value === option.value ? 'contained' : 'outlined'}
                color="grey"
                onClick={() => onChange(option.value)}
                sx={{ flex: 1 }}
            >
                {option.label}
            </Button>
        ))}
    </Stack>
);

export default DataSelector;
