export const formatPhoneNumber = (value?: string | null) => {
    if (!value) return '';

    const pureValue = value.replace(/[-()+\s]/g, '');

    const countryCode = pureValue.slice(0, 1);
    const areaCode = `(${pureValue.slice(1, 4)})`;
    const firstPart = pureValue.slice(4, 7);
    const secondPart = pureValue.slice(7);

    if (pureValue.length <= 3) return pureValue;
    if (pureValue.length <= 4) return `+${countryCode} ${areaCode}`;
    if (pureValue.length <= 7) return `+${countryCode} ${areaCode} ${firstPart}`;
    return `+${countryCode} ${areaCode} ${firstPart}-${secondPart}`;
};
