import { Box, Tab, Tabs, styled, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import { ignoreProps } from '~/lib/styled';
import { DailyTasksByTabStrict, TabKey, TabName } from '~/types/dailyTasks.d';

import { useCalls } from '../../eCallComponents/useCalls';

type TabStyledProps = {
    addAnimation: boolean;
};

const MobileTabs = styled(
    Tabs,
    ignoreProps<TabStyledProps>('addAnimation')
)<TabStyledProps>(({ theme, addAnimation }) => ({
    '& .MuiTab-root': {
        color: '#ffffffd0 !important',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 1.5,
        paddingInline: '8px',

        padding: '0 8px 12px 8px',
        // Add transition for smooth animation
        transition: addAnimation ? 'flex 300ms ease-in-out' : undefined,
        // Allow shrinking
        flex: addAnimation ? '0 1 auto' : 1,
        minWidth: 'fit-content', // Ensure it can shrink to content

        '&.Mui-selected': {
            padding: '0 8px 6px 8px',
            color: 'white !important',
            fontWeight: 700,
            flex: addAnimation ? '1 0 auto' : 1,
            borderBottom: `6px solid ${theme.palette.primary[200] as string}`,
        },
    },

    '& .MuiTabs-flexContainer': {
        gap: '4px',
    },

    '& .MuiTabs-indicator': {
        display: 'none',
    },
}));

const DesktopTabs = styled(
    Tabs,
    ignoreProps<TabStyledProps>('addAnimation')
)<TabStyledProps>(({ theme, addAnimation }) => ({
    overflowY: 'auto',
    paddingTop: '0 !important',

    '& .MuiTab-root': {
        color: `${theme.palette.grey[300]} !important`,
        paddingInline: '16px',
        paddingBottom: '12px',
        padding: '0 16px 6px 16px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: 1.7,
        // Add transition for smooth animation
        transition: addAnimation ? 'flex 300ms ease-in-out' : undefined,
        // Allow shrinking
        flex: addAnimation ? '0 1 auto' : 1,
        // Ensure it can shrink to content
        minWidth: { sm: 'fit-content', md: '250px' },
        maxWidth: 'unset',
        borderBottom: `6px solid ${theme.palette.grey[50]}`,

        '&.Mui-selected': {
            padding: '0 16px 6px 16px',
            color: `${theme.palette.primary[300] as string} !important`,
            fontWeight: 700,
            // Selected tab will grow
            flex: addAnimation ? '1 0 auto' : 1,
            borderBottom: `6px solid ${theme.palette.primary[200] as string}`,
        },

        '&:not(.Mui-selected)': {
            opacity: 'unset',
        },
    },

    '& .MuiTabs-indicator': {
        display: 'none',
    },
}));

type TabProps = {
    tabs: DailyTasksByTabStrict;
    selectedTab: TabKey;
    onTabChange: (tabKey: TabKey) => void;
    shiftDay?: DateTime;
    shiftId?: number;
};

const friendlyTabName: Record<TabName, string> = {
    pending: 'To do',
    completed: 'Done',
    calls: 'Live Calls',
};

export const TabSelector = ({ tabs, selectedTab, shiftDay, shiftId, onTabChange }: TabProps) => {
    const { breakpoints } = useTheme();
    const { undocumentedCalls } = useCalls(shiftDay && shiftId ? { shiftDay, shiftId } : undefined);
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const selectedTabIndex = useMemo(() => tabs.findIndex(({ key }) => key === selectedTab), [tabs, selectedTab]);

    const handleTabIndexChange = (tabIndex: number) => {
        const newTabKey = tabs[tabIndex].key;
        onTabChange(newTabKey);
    };

    const Tabs = isMobile ? MobileTabs : DesktopTabs;

    return (
        <Tabs
            addAnimation={tabs.length > 2}
            sx={{ mt: '8px' }}
            value={selectedTabIndex}
            onChange={(_, tabIndex) => handleTabIndexChange(tabIndex as number)}
        >
            {tabs.map(({ key, tabName, tasks }) => {
                const isSelectedTab = key === selectedTab;
                const isTodoTab = key === TabKey.PENDING;

                return (
                    <Tab
                        key={key}
                        label={friendlyTabName[tabName]}
                        icon={
                            <Box
                                sx={({ palette }) => ({
                                    color: {
                                        xs: palette.primary[500] as string,
                                        sm: isSelectedTab ? palette.common.white : palette.grey[300],
                                    },
                                    bgcolor: {
                                        xs: isSelectedTab ? palette.common.white : (palette.primary[300] as string),
                                        sm: isSelectedTab ? (palette.primary[300] as string) : palette.grey[50],
                                    },
                                    fontWeight: isSelectedTab ? 700 : 500,
                                    minWidth: '20px',
                                    height: '20px',
                                    borderRadius: '16px',
                                    display: 'flex',
                                    px: '4px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                })}
                            >
                                {/* TODO: Include documented calls for 'done' tab (AH-1750) */}
                                {isTodoTab ? tasks.length + undocumentedCalls.calls.length : tasks.length}
                            </Box>
                        }
                        iconPosition="end"
                        sx={{
                            textWrap: 'nowrap',
                            minWidth: { sm: 'fit-content', md: '250px' },
                        }}
                    />
                );
            })}
        </Tabs>
    );
};
