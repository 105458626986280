import CareStaff from './CareStaff';
import Days from './Days';
import Resident from './Resident';
import Shift from './Shift';

export default {
    groupings: {
        Shift,
        Days,
        CareStaff,
        Resident,
    },
};
