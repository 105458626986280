import { Box, Stack, ThemeProvider } from '@mui/material';
import { Skeleton } from '@mui/material';
import { useAtomValue } from 'jotai';
import range from 'lodash/range';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';

import theme from '~/pages/OperationsV2/theme';
import { usePermissions } from '~/permissions/utils';

import { useGetDocumentedCalls } from '../../../api/queries/call/getDocumentedCalls';

import { searchQueryAtom } from './atom';
import { CallCard } from './components/CallCard';
import { CallDetailsBottomSheet } from './components/CallDetailsBottomSheet';
import { Option, SearchResults } from './components/SearchResults';

const removeDuplicates = (options: Option[]) => {
    const uniqueOptions = new Set(options.map((option) => option.id));

    return Array.from(uniqueOptions).map((id) => options.find((option) => option.id === id)!);
};

export const CallsTab = ({ shiftDay, shiftId }: { shiftDay: DateTime; shiftId: number }) => {
    const hasPermission = usePermissions();

    const { data: documentedCalls, isLoading: isDocumentedCallsLoading } = useGetDocumentedCalls({
        enabled: !!hasPermission('Community', 'ecall-caregiver'), // && !isGetCareLocationsLoading,
        // TODO: Re-enable this after migrating the header 'location'
        // selector to care locations for the 'done' tab (AH-1752)
        // careLocationIds: careLocationsIds,
        shiftDay,
        shiftId,
    });

    const [selectedCallId, setSelectedCallId] = useState<number | null>(null);
    const searchQuery = useAtomValue(searchQueryAtom);

    const options = useMemo<Option[]>(() => {
        if (!documentedCalls || !searchQuery) return [];

        const residents = documentedCalls
            .filter((call) => call.resident)
            .map(
                (call) =>
                    ({
                        id: call.resident!.id.toString(),
                        value: call.resident!.name,
                        location: call.resident!.roomNumber
                            ? `Apt ${call.resident!.roomNumber}`
                            : call.deviceLocation?.roomNumber || call.deviceLocation?.zoneName || 'Unknown',
                        type: 'resident',
                    }) satisfies Option
            );

        const staff = documentedCalls
            .filter((call) => call.attendedByUser)
            .map(
                (call) =>
                    ({
                        id: call.attendedByUser.id.toString(),
                        value: call.attendedByUser.name,
                        type: 'staff',
                    }) satisfies Option
            );

        return [...removeDuplicates(residents), ...removeDuplicates(staff)];
    }, [documentedCalls, searchQuery]);

    if (isDocumentedCallsLoading) {
        return (
            <Stack paddingInline="24px" gap="8px">
                {range(3).map((index) => (
                    <Skeleton key={index} width="100%" height="200px" />
                ))}
            </Stack>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            {searchQuery?.trim() ? (
                <Box paddingInline="24px">
                    <SearchResults searchQuery={searchQuery} options={options} />
                </Box>
            ) : (
                <Stack paddingInline="24px" gap="8px">
                    {/* TODO: Empty view for no calls (AH-1751) */}
                    {documentedCalls?.map((call) => <CallCard key={call.id} {...call} onClick={setSelectedCallId} />)}
                </Stack>
            )}
            <CallDetailsBottomSheet
                open={!!selectedCallId}
                onClose={() => setSelectedCallId(null)}
                callId={selectedCallId ?? 0}
            />
        </ThemeProvider>
    );
};
