import { atom } from 'jotai';
import { DateTime } from 'luxon';

import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { atomWithStorage, atomWithToggle } from '~/state/atomUtils';
import { TabKey } from '~/types/dailyTasks.d';

export const selectedDateAtom = atom<DateTime<true> | null>(null);
export const isLocationSelectorDialogOpenAtom = atomWithToggle(false);
export const isShiftSelectorDialogOpenAtom = atomWithToggle(false);
export const isSortDialogOpenAtom = atomWithToggle(false);
export const selectedAssignmentIdsAtom = atomWithStorage<number[]>('header_assignmentIds', [ALL_ASSIGNMENTS_ID]);
export const selectedTabAtom = atom<TabKey>(TabKey.LIVE_CALLS);
export const openedResidentIdAtom = atom<number | null>(null);
export const isFiltersExpandedAtom = atomWithStorage('header_isFiltersExpanded', true);
