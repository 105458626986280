import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

import { api } from '~/api';
import { BASE_RESIDENT_PHOTO_PATH } from '~/constants/residents';

import { useErrorHandledQuery, useToken } from '../common';

export const useResidentPhoto = (photoFileName: string | null) => {
    const photoPath = photoFileName ? `${BASE_RESIDENT_PHOTO_PATH}/${photoFileName}` : undefined;
    return useMediaQuery(photoPath);
};

export const useMediaQuery = (path?: string) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options: AxiosRequestConfig = {
            headers: {
                authorization: token,
                Accept: 'image/jpeg',
            },
            responseType: 'arraybuffer',
        };

        const response = await api.get(path!, options);
        return URL.createObjectURL(new Blob([response.data], { type: 'image/jpeg' }));
    }, [path, token]);

    return useErrorHandledQuery({
        queryKey: [path],
        queryFn,
        enabled: !!path,
        staleTime: 3600000, //1h
    });
};
