import { Box, alpha } from '@mui/material';
import { Stack, styled } from '@mui/material';
import React, { ComponentProps, ReactNode } from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';

const ROW_PADDING_Y_PX = 20;

const Divider = styled(Box)(({ theme: { palette } }) => ({
    height: 1,
    backgroundColor: alpha(palette.grey[900], 0.5),
}));

type DataLegendProps = {
    rows: { label: string; values: ReactNode[] }[];
    colorMapping: string[];
    containerProps?: ComponentProps<typeof Stack>;
    isSelected?: boolean;
};

const DataLegend = ({ rows, colorMapping, containerProps, isSelected = true }: DataLegendProps) => (
    <Stack {...containerProps} divider={<Divider />}>
        {rows.map(({ label, values }, rowIndex) => (
            <Stack
                key={label}
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    p:
                        rowIndex === 0
                            ? `0 0 ${ROW_PADDING_Y_PX}px`
                            : rowIndex === rows.length - 1
                              ? `${ROW_PADDING_Y_PX}px 0 0`
                              : `${ROW_PADDING_Y_PX}px 0`,
                    gap: '8px',
                }}
            >
                <Box
                    sx={{
                        bgcolor: isSelected ? colorMapping[rowIndex] : 'white',
                        width: '16px',
                        height: '16px',
                        borderRadius: '2px',
                        border: '2px solid',
                        borderColor: colorMapping[rowIndex],
                    }}
                />
                <SingleLineTypography>{label}</SingleLineTypography>
                <Stack
                    sx={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        gap: '8px',
                    }}
                >
                    {values.map((value, valueIndex) => (
                        <SingleLineTypography
                            key={valueIndex}
                            sx={{
                                flexShrink: 0,
                                minWidth: '32px',
                                textAlign: 'right',
                            }}
                        >
                            {value}
                        </SingleLineTypography>
                    ))}
                </Stack>
            </Stack>
        ))}
    </Stack>
);

export default DataLegend;
