import { styled } from '@mui/material';
import React from 'react';

import { fontStyle } from '~/pages/OperationsV2/theme/typography';
import { Item, TooltipProps } from '~/pages/OperationsV2/utils/recharts';

const TOOLTIP_HEIGHT_PX = 24;

const TooltipContainer = styled('div')(({ theme: { palette } }) => ({
    color: palette.grey[700],
    background: 'white',
    display: 'inline-block',
    height: `${TOOLTIP_HEIGHT_PX}px`,
    padding: '0 4px',
    borderRadius: `${TOOLTIP_HEIGHT_PX / 2}px`,
    borderColor: palette.grey[500],
    // Border is set at call site to avoid clipping
}));

const TooltipText = styled('span')({
    ...fontStyle,
    fontSize: '12px',
    fontWeight: 700,
});

type DataSimpleBarChartTooltipProps<T extends Item> = {
    renderLabel: (item: T, dataKey: keyof T) => string | null;
};

const DataSimpleBarChartTooltip = <T extends Item>({
    active,
    payload,
    renderLabel,
}: TooltipProps<T> & DataSimpleBarChartTooltipProps<T>) => {
    if (!active || !payload || !payload.length) return null;

    const { payload: item, dataKey } = payload[0];

    return (
        <TooltipContainer style={{ border: '1px solid' }}>
            <TooltipText>{renderLabel(item, dataKey as keyof T)}</TooltipText>
        </TooltipContainer>
    );
};

export default DataSimpleBarChartTooltip;
