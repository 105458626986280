import { styled } from '@mui/material';
import React from 'react';

import { Item, TickProps } from '~/pages/OperationsV2/utils/recharts';

const Dot = styled('circle')(({ theme: { palette } }) => ({
    fill: palette.grey[900],
    fillOpacity: 0.2,
}));

type DataSimpleBarChartXAxisTickProps<T extends Item, TX extends keyof T> = {
    chartHeight: number;
    items: T[];
    dataKey: TX;
    isSelected: (item: T) => boolean;
};

const DataSimpleBarChartXAxisTick = <T extends Item, TX extends keyof T>({
    x,
    payload: { value },
    chartHeight,
    items,
    dataKey,
    isSelected: isSelectedFn,
}: TickProps<T, TX> & DataSimpleBarChartXAxisTickProps<T, TX>) => {
    // Recharts only provides the x-axis value here, not the entire item payload
    const item = items.find((item) => item[dataKey] === value);
    if (!item) return null;

    if (!isSelectedFn(item)) return null;

    return <Dot cx={x} cy={chartHeight - 18} r={4} />;
};

export default DataSimpleBarChartXAxisTick;
