import React from 'react';
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from 'recharts';

import { Item, TickProps, TooltipProps } from '~/pages/OperationsV2/utils/recharts';
import { StringKeyOf } from '~/pages/OperationsV2/utils/types';

import DataSegmentedBarChartTooltip from './DataSegmentedBarChartTooltip';
import DataSegmentedBarChartXAxisTick from './DataSegmentedBarChartXAxisTick';

const BAR_WIDTH_PX = 12;

// Colors for each portion of the bar, top to bottom
export type DataSegmentedBarChartColorMapping = Record<'selected' | 'unselected', string[]>;

type DataSegmentedBarChartProps<T extends Item, TX extends StringKeyOf<T>> = {
    width: number;
    height: number;
    items: T[];
    xAxisDataKey: TX;
    yAxisDataKeys: StringKeyOf<T>[];
    renderXAxisLabel: (item: T) => string;
    renderTooltipLabel: (item: T, dataKey: StringKeyOf<T>) => string | null;
    colorMapping: DataSegmentedBarChartColorMapping;
    isSelected: (item: T) => boolean;
};

const DataSegmentedBarChart = <T extends Item, TX extends StringKeyOf<T>>({
    width,
    height,
    items,
    xAxisDataKey,
    yAxisDataKeys,
    renderXAxisLabel,
    renderTooltipLabel,
    colorMapping,
    isSelected,
}: DataSegmentedBarChartProps<T, TX>) => (
    <BarChart width={width} height={height} data={items} reverseStackOrder style={{ userSelect: 'none' }}>
        {yAxisDataKeys.map((key, keyIndex) => (
            <Bar
                key={key}
                type="linear"
                dataKey={key}
                stackId="stack"
                barSize={BAR_WIDTH_PX}
                radius={
                    keyIndex === 0
                        ? [BAR_WIDTH_PX / 2, BAR_WIDTH_PX / 2, 0, 0] // Rounded top corners
                        : keyIndex === yAxisDataKeys.length - 1
                          ? [0, 0, BAR_WIDTH_PX / 2, BAR_WIDTH_PX / 2] // Rounded bottom corners
                          : [0, 0, 0, 0]
                }
            >
                {items.map((item, itemIndex) => (
                    <Cell key={itemIndex} fill={colorMapping[isSelected(item) ? 'selected' : 'unselected'][keyIndex]} />
                ))}
            </Bar>
        ))}
        <Tooltip
            content={(props: TooltipProps<T>) => (
                <DataSegmentedBarChartTooltip {...props} renderLabel={renderTooltipLabel} />
            )}
            cursor={false}
            shared={false}
            isAnimationActive={false}
        />
        <XAxis
            dataKey={xAxisDataKey}
            interval={0} // Show all ticks
            padding={{ left: 8, right: 8 }}
            axisLine={false}
            tickLine={false}
            tick={(props: TickProps<T, typeof xAxisDataKey>) => (
                <DataSegmentedBarChartXAxisTick
                    {...props}
                    chartHeight={height}
                    items={items}
                    dataKey={xAxisDataKey}
                    isSelected={isSelected}
                    renderLabel={renderXAxisLabel}
                />
            )}
        />
        <YAxis domain={[0, 'dataMax']} hide />
    </BarChart>
);

export default DataSegmentedBarChart;
