import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';

type Data = DetailsGroupingDataType<'REGION', 'CARE_REVENUE', 'ON_DEMAND_CARE', 'ALL'>;

const Data = ({ data: { regionItems, communityItems } }: { data: Data }) => {
    return (
        <DataContainer>
            <DataTable
                containerProps={{ sx: { marginBottom: '24px', minWidth: '100%', overflowX: 'auto' } }}
                columns={{
                    regionName: {
                        label: 'Region',
                        allowSort: false,
                        weight: 1,
                    },
                    residentsWithAssistLevelIncreaseCount: {
                        label: 'Residents with\nAssist Level Increase',
                        allowSort: false,
                        weight: 1,
                    },
                }}
                rows={regionItems}
            />
            <DataTable
                containerProps={{ sx: { maxWidth: '100%', overflowX: 'auto' } }}
                columns={{
                    residentName: {
                        label: 'Resident',
                        renderCell: (value, { row }) => (
                            <Stack sx={{ gap: '2px' }}>
                                <Typography>{value}</Typography>
                                <Typography variant="caption">{row.branchName}</Typography>
                            </Stack>
                        ),
                    },
                    prnCount: { label: 'PRN Count' },
                    adlDeviation: {
                        label: 'ADL Deviation',
                        renderCell: (value) => <Notes notes={value.toString()} maxWidth="200px" />,
                    },
                    numberOfCalls: { label: 'Number of Calls' },
                    careType: { label: 'Care Type' },
                    careLevel: { label: 'Care Level /\nAssessment Points' },
                    lastAssessmentDate: {
                        label: 'Last\nAssessment',
                        renderCell: (value) => DateTime.fromISO(value).toFormat('MMM d, yyyy'),
                    },
                    upcomingAssessmentDate: {
                        label: 'Upcoming\nAssessment',
                        renderCell: (value) => DateTime.fromISO(value).toFormat('MMM d, yyyy'),
                    },
                    onHospice: {
                        label: 'Hospice Status',
                        renderCell: (value) => (value ? 'On Hospice' : 'Not On Hospice'),
                    },
                }}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
