import { alpha, styled } from '@mui/material';
import React from 'react';

import { DotProps, Item } from '~/pages/OperationsV2/utils/recharts';
import { ignoreProps } from '~/pages/OperationsV2/utils/styled';

import { SelectableProps } from '../shared';

const IndicatorLine = styled(
    'line',
    ignoreProps<SelectableProps>('isSelected')
)<SelectableProps>(({ theme: { palette }, isSelected }) => ({
    stroke: alpha(palette.grey[900], isSelected ? 0.5 : 0.2),
    strokeWidth: '2px',
}));

type DataMultiLineChartDotProps<T extends Item, TX extends keyof T> = {
    chartHeight: number;
    dataKey: TX;
    keyIndex: number;
    color: string;
    isSelected: (item: T) => boolean;
    isFocused: (item: T) => boolean;
};

const DataMultiLineChartDot = <T extends Item, TX extends keyof T>({
    cx,
    cy,
    payload: item,
    chartHeight,
    dataKey,
    keyIndex,
    color,
    isSelected: isSelectedFn,
    isFocused: isFocusedFn,
}: DotProps<T> & DataMultiLineChartDotProps<T, TX>) => {
    if (!item[dataKey]) return null;

    const isSelected = isSelectedFn(item);
    const isFocused = isFocusedFn(item);

    if (!isSelected && !isFocused) return null;

    return (
        <>
            {/* We want to render the indicator line before any
            dot and a single time so it doesn't overlap them */}
            {keyIndex === 0 && (
                <IndicatorLine
                    x1={cx}
                    x2={cx}
                    y1={0}
                    y2={chartHeight - 40} // Subtract label/caption height
                    isSelected={isSelected}
                />
            )}
            <circle cx={cx} cy={cy} r={6} fill={color} />
            {!isSelected && isFocused && <circle cx={cx} cy={cy} r={4} fill="white" />}
        </>
    );
};

export default DataMultiLineChartDot;
