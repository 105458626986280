import { Avatar, Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { useResidentPhoto } from '~/api/queries/media';
import langDictionary from '~/app-strings';
import { pxToRem } from '~/components/theme/typography';
import { ResidentResponse } from '~/types/residents';

const ResidentContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    })
);

const ResidentName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { xs: pxToRem(16), md: pxToRem(18) },
        fontWeight: 700,
    })
);

const ResidentRoom = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(24),
        color: theme.palette.secondary.main,
        fontSize: { xs: pxToRem(14), md: pxToRem(16) },
        fontWeight: 400,
    })
);

const ResidentPhotoContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
        mx: pxToRem(8),
    })
);

const ResidentAvatar = styled(Avatar)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(64),
        height: pxToRem(64),
    })
);

const ResidentStatus = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(4),
        display: 'flex',
        borderRadius: pxToRem(4),
        color: '#006B75',
        backgroundColor: '#006B7514',
    })
);

const DividerStyle = styled(Divider)(({ theme }) =>
    theme.unstable_sx({
        my: pxToRem(16),
    })
);

const ResidentDetailsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    })
);

const ResidentDetailsBlock = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    })
);

const DetailsConcept = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { xs: pxToRem(11), md: pxToRem(13) },
    })
);

const DetailsDescription = styled(Typography)<{ isNotSet?: boolean }>(({ theme, isNotSet }) =>
    theme.unstable_sx({
        color: theme.palette.common.black,
        fontSize: { xs: pxToRem(12), md: pxToRem(14) },
        fontWeight: 'bold',
        height: pxToRem(15),
        minWidth: pxToRem(30),
        fontStyle: isNotSet ? 'italic' : undefined,
        textAlign: 'center',
    })
);

type Props = {
    residentData: ResidentResponse;
};

const ResidentTopContainer = (props: Props) => {
    const { residentData } = props;
    const { residents: residentsDictionary } = langDictionary;
    const { data: imgURL } = useResidentPhoto(residentData?.photo);

    const {
        firstName = '',
        lastName = '',
        buildingStatus,
        careTypeCode,
        zoneName = null,
        roomNumber = null,
        codeStatus,
    } = residentData;

    const fullName = `${firstName} ${lastName}`;

    return (
        <>
            <ResidentContainer>
                <ResidentName>{fullName}</ResidentName>
                <ResidentRoom>
                    {zoneName ?? 'Floor not set'} / Apt {roomNumber ?? 'not set'}
                </ResidentRoom>
                <ResidentPhotoContainer>
                    <ResidentAvatar alt={fullName} src={imgURL} />
                </ResidentPhotoContainer>
                {codeStatus && <ResidentStatus variant="body2">{codeStatus}</ResidentStatus>}
            </ResidentContainer>
            <DividerStyle />
            <ResidentDetailsContainer>
                <ResidentDetailsBlock>
                    <DetailsDescription isNotSet={!buildingStatus}>{buildingStatus ?? 'Not set'}</DetailsDescription>
                    <DetailsConcept variant="caption">{residentsDictionary.status}</DetailsConcept>
                </ResidentDetailsBlock>
                <ResidentDetailsBlock>
                    <DetailsDescription isNotSet={!careTypeCode}>{careTypeCode ?? 'Not set'}</DetailsDescription>
                    <DetailsConcept variant="caption">{residentsDictionary.focus}</DetailsConcept>
                </ResidentDetailsBlock>
                <ResidentDetailsBlock>
                    {/* TODO: [AH-984] set actual assist level */}
                    <DetailsDescription>-</DetailsDescription>
                    <DetailsConcept variant="caption">{residentsDictionary.transfer}</DetailsConcept>
                </ResidentDetailsBlock>
            </ResidentDetailsContainer>
            <DividerStyle />
        </>
    );
};

export default ResidentTopContainer;
