import AvgResponseTime from './AvgResponseTime';
import AvgResponseTimeByCareStaff from './AvgResponseTimeByCareStaff';
import AvgResponseTimeByResident from './AvgResponseTimeByResident';
import LongestResponseTime from './LongestResponseTime';

export default {
    AvgResponseTime,
    AvgResponseTimeByCareStaff,
    AvgResponseTimeByResident,
    LongestResponseTime,
};
