import All from './All';
import Anomaly from './Anomaly';
import CareIncrease from './CareIncrease';
import HeavyHitter from './HeavyHitter';

export default {
    groupings: {
        HeavyHitter,
        Anomaly,
        CareIncrease,
        All,
    },
};
