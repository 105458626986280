import {
    Button,
    ButtonProps,
    IconButton,
    IconButtonProps,
    Stack,
    Typography,
    alpha,
    styled,
    useTheme,
} from '@mui/material';
import { ArrowLeft, CaretLeft, CaretRight, List, X } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { Period } from '@allie/operations-common/src/types/shared';

import { ReactComponent as FilterIcon } from '~/assets/icon/icon-filter.svg';
import { isDrawerOpenAtom } from '~/components/Layout/MobileDrawer';
import { useRouteHandle } from '~/router';

import { selectedDateAtom, selectedPeriodAtom } from '../../../atoms';
import { isFilterListExpandedAtom } from '../shared';

import FilterList from './FilterList';

const Container = styled(Stack)(({ theme: { palette } }) => ({
    color: palette.grey[900],
    backgroundColor: palette.primary[300] as string,
    zIndex: 10,
}));

// Copied from src/components/Layout/MobileHeader.tsx with changes to icons
const DrawerButton = (props: IconButtonProps) => {
    const navigate = useNavigate();
    const { backOne, backTo } = useRouteHandle();

    const setIsDrawerOpen = useSetAtom(isDrawerOpenAtom);

    const handleGoBackClick = () => {
        if (backOne) navigate(-1);
        else if (backTo) navigate(backTo, { replace: true });
    };

    return backOne || backTo ? (
        <IconButton size="medium" onClick={handleGoBackClick} color="inherit" {...props}>
            <ArrowLeft weight="bold" />
        </IconButton>
    ) : (
        <IconButton size="medium" onClick={() => setIsDrawerOpen(true)} color="inherit" {...props}>
            <List weight="bold" />
        </IconButton>
    );
};

const CaretButton = ({ direction }: { direction: 'left' | 'right' }) => {
    const setSelectedDate = useSetAtom(selectedDateAtom);
    const selectedPeriod = useAtomValue(selectedPeriodAtom);

    const handleClick = () => {
        setSelectedDate((date) => date.plus({ [selectedPeriod]: direction === 'left' ? -1 : 1 }));
    };

    return (
        <IconButton size="medium" color="inherit" onClick={handleClick}>
            {direction === 'left' ? <CaretLeft weight="bold" /> : <CaretRight weight="bold" />}
        </IconButton>
    );
};

const Date = () => {
    const selectedPeriod = useAtomValue(selectedPeriodAtom);
    const selectedDate = useAtomValue(selectedDateAtom);

    const formattedDate = useMemo(() => {
        const now = DateTime.now();

        let startOfWeek: DateTime;
        let endOfWeek: DateTime;

        // Trying to keep different patterns around the same length
        switch (selectedPeriod) {
            case 'DAY':
                return selectedDate.hasSame(now, 'year')
                    ? selectedDate.toFormat('LLLL d') // e.g. 'January 1'
                    : selectedDate.toFormat('LLL d, yyyy'); // e.g. 'Jan 1, 2025'

            case 'WEEK':
                startOfWeek = selectedDate.startOf('week');
                endOfWeek = selectedDate.endOf('week');

                return startOfWeek.hasSame(now, 'year') && endOfWeek.hasSame(now, 'year')
                    ? startOfWeek.month === endOfWeek.month
                        ? `${startOfWeek.toFormat('LLLL d')} - ${endOfWeek.toFormat('d')}` // e.g. 'January 1 - 7'
                        : `${startOfWeek.toFormat('LLL d')} - ${endOfWeek.toFormat('LLL d')}` // e.g. 'Jan 30 - Feb 5'
                    : startOfWeek.month === endOfWeek.month
                      ? `${startOfWeek.toFormat('LLL d')} - ${endOfWeek.toFormat('d, yyyy')}` // e.g. 'Jan 1 - 7, 2025'
                      : `${startOfWeek.toFormat('LLL d')} - ${endOfWeek.toFormat('LLL d, yyyy')}`; // e.g. 'Jan 30 - Feb 5, 2025'

            case 'MONTH':
                return selectedDate.hasSame(now, 'year')
                    ? selectedDate.toFormat('MMMM') // e.g. 'January'
                    : selectedDate.toFormat('MMM yyyy'); // e.g. 'Jan 2025'
        }
    }, [selectedPeriod, selectedDate]);

    return (
        <Typography variant="subtitle2" sx={{ userSelect: 'none' }}>
            {formattedDate}
        </Typography>
    );
};

const HeadingRow = () => (
    <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        p="8px"
        spacing="8px"
        width="100%"
        height="56px"
        position="relative"
    >
        <DrawerButton
            sx={{
                position: 'absolute',
                top: '50%',
                left: '16px',
                transform: 'translateY(-50%)',
            }}
        />
        <CaretButton direction="left" />
        <Date />
        <CaretButton direction="right" />
    </Stack>
);

const PeriodButton = ({ period, title }: { period: Period; title: string }) => {
    const setSelectedDate = useSetAtom(selectedDateAtom);
    const [selectedPeriod, setSelectedPeriod] = useAtom(selectedPeriodAtom);

    const handleClick = () => {
        setSelectedDate((date) => date.startOf(period.toLocaleLowerCase() as 'day' | 'week' | 'month'));
        setSelectedPeriod(period);
    };

    return (
        <Button
            color={selectedPeriod === period ? 'primary' : 'secondary'}
            onClick={handleClick}
            sx={({ palette }) => ({
                flex: 1,
                ...(selectedPeriod !== period && {
                    bgcolor: alpha(palette.grey[900], 0.1),
                    fontWeight: 500,
                    '&:hover': { bgcolor: alpha(palette.grey[900], 0.2) },
                }),
            })}
        >
            {title}
        </Button>
    );
};

const FilterButton = ({ sx, ...props }: ButtonProps) => {
    const { palette } = useTheme();

    const [isFilterListExpanded, setIsFilterListExpanded] = useAtom(isFilterListExpandedAtom);

    return (
        <Button
            color={isFilterListExpanded ? 'primary' : 'secondary'}
            onClick={() => setIsFilterListExpanded(!isFilterListExpanded)}
            sx={{
                position: 'relative',
                ...(!isFilterListExpanded && {
                    bgcolor: alpha(palette.grey[900], 0.1),
                    fontWeight: 500,
                    '&:hover': { bgcolor: alpha(palette.grey[900], 0.2) },
                }),
                ...sx,
            }}
            {...props}
        >
            <X
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    ...(isFilterListExpanded
                        ? {
                              opacity: 1,
                              transition: 'opacity 0.3s ease-in',
                          }
                        : {
                              opacity: 0,
                              transition: 'opacity 0.3s ease-out',
                          }),
                }}
            />
            <FilterIcon
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    ...(isFilterListExpanded
                        ? {
                              opacity: 0,
                              transition: 'opacity 0.3s ease-out',
                          }
                        : {
                              opacity: 1,
                              transition: 'opacity 0.3s ease-in',
                          }),
                }}
            />
        </Button>
    );
};

const PeriodRow = () => (
    <Stack direction="row" p="0 24px 8px" spacing="4px" width="100%">
        <PeriodButton period="DAY" title="Day" />
        <PeriodButton period="WEEK" title="Week" />
        <PeriodButton period="MONTH" title="Month" />
        <FilterButton sx={{ ml: '8px !important' }} />
    </Stack>
);

const MobileHeader = () => (
    <Container>
        <HeadingRow />
        <PeriodRow />
        <FilterList />
    </Container>
);

export default MobileHeader;
