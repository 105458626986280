import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import { DataWithChangePercentage } from '~/pages/OperationsV2/components/Module/Details/DataWithChangePercentage';

type Data = DetailsGroupingDataType<'REGION', 'RISK_AND_COMPLIANCE', 'TOTAL_CALLS', 'ALL'>;

const Data = ({ data: { regionItems, communityItems } }: { data: Data }) => {
    return (
        <DataContainer>
            <DataTable
                containerProps={{ sx: { marginBottom: '24px' } }}
                columns={{
                    regionName: { label: 'Region', allowSort: false },
                    totalCount: {
                        label: 'Total Calls',
                        allowSort: false,
                        renderCell: (value, { row }) => (
                            <DataWithChangePercentage value={value} percentageChange={row.totalCountChangePercentage} />
                        ),
                    },
                    emergencyCount: {
                        label: 'Emergency\n(Fall/911/Other)',
                        allowSort: false,
                        renderCell: (value, { row }) => (
                            <DataWithChangePercentage value={value} percentageChange={row.emergencyChangePercentage} />
                        ),
                    },
                    regularCount: {
                        label: 'Non\nEmergency',
                        allowSort: false,
                        renderCell: (value, { row }) => (
                            <DataWithChangePercentage value={value} percentageChange={row.regularChangePercentage} />
                        ),
                    },
                }}
                rows={regionItems}
            />
            <DataTable
                columns={{
                    branchName: { label: 'Branch' },
                    totalCount: {
                        label: 'Total Calls',
                        renderCell: (value, { row }) => (
                            <DataWithChangePercentage value={value} percentageChange={row.totalCountChangePercentage} />
                        ),
                    },
                    emergencyCount: {
                        label: 'Emergency\n(Fall/911/Other)',
                        renderCell: (value, { row }) => (
                            <DataWithChangePercentage value={value} percentageChange={row.emergencyChangePercentage} />
                        ),
                    },
                    regularCount: {
                        label: 'Non\nEmergency',
                        renderCell: (value, { row }) => (
                            <DataWithChangePercentage value={value} percentageChange={row.regularChangePercentage} />
                        ),
                    },
                }}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
