import { Stack } from '@mui/material';
import React, { ComponentProps } from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';

import SeeMoreButton from '../../SeeMore';

const Notes = ({ notes, ...props }: { notes: string } & ComponentProps<typeof Stack>) => (
    <Stack {...props}>
        {/* Using a Box and parent -> child styling to get a larger clickable area */}
        <SingleLineTypography>{notes}</SingleLineTypography>
        <SeeMoreButton onClick={() => {} /* TODO: Add notes system */} />
    </Stack>
);

export default Notes;
