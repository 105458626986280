import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { PiCircleFill } from 'react-icons/pi';

import { usePermissions } from '~/permissions/utils';

const findAllOccurrences = (text: string, searchTerm: string) => {
    const positions: number[] = [];
    let currentPosition = -1;

    // Keep searching until no more matches are found
    do {
        // Find next occurrence starting after previous match
        const nextPosition = text.toLowerCase().indexOf(searchTerm.toLowerCase(), currentPosition + 1);

        // If no more matches found, stop searching
        if (nextPosition === -1) break;

        // Store the position and update current position
        positions.push(nextPosition);
        currentPosition = nextPosition;
        // eslint-disable-next-line no-constant-condition
    } while (true);

    return positions;
};
const splitString = (value: string, searchQuery: string) => {
    const allOccurrences = findAllOccurrences(value, searchQuery);
    const result: string[] = [];

    allOccurrences.forEach((position, index) => {
        // Add the part before the match
        if (index === 0) {
            result.push(value.slice(0, position));
        } else {
            // Add the part between the previous match and current match
            result.push(value.slice(allOccurrences[index - 1] + searchQuery.length, position));
        }

        // Add the matched part
        result.push(value.slice(position, position + searchQuery.length));
    });

    // Add the remaining part after the last match
    if (allOccurrences.length > 0) {
        result.push(value.slice(allOccurrences[allOccurrences.length - 1] + searchQuery.length));
    }

    return result.filter((part) => part !== ''); // Remove empty strings
};

export type Option = {
    id: string;
    value: string;
    location?: string;
    type: 'resident' | 'staff';
};

const SearchOption = ({ option, searchQuery }: { option: Option; searchQuery: string }) => {
    const { palette } = useTheme();

    const splittedValue = useMemo(() => splitString(option.value, searchQuery), [option.value, searchQuery]);

    return (
        <Box
            display="flex"
            alignItems="center"
            gap="8px"
            padding="8px"
            borderRadius="8px"
            sx={{ ':hover': { backgroundColor: palette.secondary[100] as string } }}
        >
            {option.type === 'resident' && <PiCircleFill size={24} color={palette.grey[100]} />}
            <Typography variant="body2" flex={1}>
                {splittedValue.map((value, index) => {
                    if (value.toLowerCase() !== searchQuery.toLowerCase()) return value;

                    return (
                        <span
                            key={index}
                            style={{ backgroundColor: palette.secondary[300] as string, paddingBlock: '4px' }}
                        >
                            {value}
                        </span>
                    );
                })}
            </Typography>
            {option.type === 'resident' && <Typography variant="body2">{option.location}</Typography>}
        </Box>
    );
};

interface SearchResultsProps {
    searchQuery: string;
    options: Option[];
}

export const SearchResults = ({ searchQuery, options }: SearchResultsProps) => {
    const { palette } = useTheme();
    const hasPermission = usePermissions();

    const { residents, staff } = useMemo(() => {
        const results = options.filter((option) => option.value.toLowerCase().includes(searchQuery.toLowerCase()));

        const residents = results.filter((option) => option.type === 'resident');
        const staff = results.filter((option) => option.type === 'staff');

        return { residents, staff };
    }, [options, searchQuery]);

    // TODO - check if is manager
    const isCaregiver = hasPermission('Community', 'ecall-caregiver');

    return (
        <Stack>
            <Typography variant="body2" color={palette.grey[500]}>
                Residents
            </Typography>
            <Box height="8px" />
            {residents.length > 0 ? (
                residents.map((option) => <SearchOption key={option.id} option={option} searchQuery={searchQuery} />)
            ) : (
                <Typography variant="body2">No residents found.</Typography>
            )}
            {!isCaregiver && (
                <>
                    <Divider sx={{ margin: '8px 0', borderColor: palette.grey[100] }} />
                    <Typography variant="body2" color={palette.grey[500]}>
                        Staff
                    </Typography>
                    <Box height="8px" />
                    {staff.length > 0 ? (
                        staff.map((option) => (
                            <SearchOption key={option.id} option={option} searchQuery={searchQuery} />
                        ))
                    ) : (
                        <Typography variant="body2">No staff found.</Typography>
                    )}
                </>
            )}
        </Stack>
    );
};
