import { styled } from '@mui/material';
import React from 'react';

import { fontStyle } from '~/pages/OperationsV2/theme/typography';
import { Item, TickProps } from '~/pages/OperationsV2/utils/recharts';
import { ignoreProps } from '~/pages/OperationsV2/utils/styled';

import { SelectableProps } from '../shared';

const TextContainer = styled(
    'text',
    ignoreProps<SelectableProps>('isSelected')
)<SelectableProps>(({ theme: { palette }, isSelected }) => ({
    ...fontStyle,
    fill: isSelected ? (palette.primary[500] as string) : palette.grey[500],
    fontSize: '12px',
    fontWeight: isSelected ? 700 : 500,
    textAnchor: 'middle',
}));

type DataSegmentedBarChartXAxisTickProps<T extends Item, TX extends keyof T> = {
    chartHeight: number;
    items: T[];
    dataKey: TX;
    renderLabel: (item: T) => string;
    isSelected: (item: T) => boolean;
};

const DataSegmentedBarChartXAxisTick = <T extends Item, TX extends keyof T>({
    x,
    payload: { value },
    chartHeight,
    items,
    dataKey,
    isSelected: isSelectedFn,
    renderLabel,
}: TickProps<T, TX> & DataSegmentedBarChartXAxisTickProps<T, TX>) => {
    // Recharts only provides the x-axis value here, not the entire item payload
    const item = items.find((item) => item[dataKey] === value);
    if (!item) return null;

    const isSelected = isSelectedFn(item);
    const [label, caption] = renderLabel(item).split('\n');

    return (
        <TextContainer isSelected={isSelected}>
            <tspan
                x={x}
                y={chartHeight - 14} // Subtract caption font size and padding/spacing
            >
                {label}
            </tspan>
            {caption && (
                <tspan x={x} y={chartHeight}>
                    {caption}
                </tspan>
            )}
        </TextContainer>
    );
};

export default DataSegmentedBarChartXAxisTick;
