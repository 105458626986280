import { Box, Stack, alpha, styled } from '@mui/material';
import { Minus, Plus } from '@phosphor-icons/react';
import React, { useRef, useState } from 'react';

import { ModuleType, SectionType } from '@allie/operations-common/src/types/module';
import { Level } from '@allie/operations-common/src/types/shared';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useCanHover } from '~/hooks/useCanHover';

import { ModuleDefinition } from '../../modules';
import { SectionData } from '../../testData';
import { ignoreProps } from '../../utils/styled';

import Section from './Section';
import { COLLAPSIBLE_ANIM_MS, CollapsibleProps, HoverableProps } from './shared';

const ModuleContainer = styled(Stack)(({ theme: { palette } }) => ({
    borderBottom: '1px solid',
    borderColor: alpha(palette.grey[900], 0.5),
}));

const ModuleLabelContainer = styled(
    Stack,
    ignoreProps<HoverableProps>('canHover')
)<HoverableProps>(({ theme: { palette }, canHover }) => ({
    color: palette.grey[900],
    backgroundColor: '#E0F0F1', // One-off color
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '72px', // Instead of 'label' line-height to prevent cropping
    padding: '0 24px',
    gap: '8px',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'background-color 0.1s',
    '&:hover': canHover && { backgroundColor: alpha(palette.primary[300] as string, 0.5) },
    '&:active': { backgroundColor: alpha(palette.primary[300] as string, 0.75) },
}));

const ModuleIconContainer = styled(Box)({
    flexShrink: 0,
    position: 'relative',
    width: '24px',
    height: '24px',
});

const ModulePlusIcon = styled(
    Plus,
    ignoreProps<CollapsibleProps>('isOpen')
)<CollapsibleProps>(({ isOpen }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    transform: `scaleY(${!isOpen ? 1 : 0})`,
    transition: `transform ${COLLAPSIBLE_ANIM_MS}ms`,
}));

const ModuleMinusIcon = styled(
    Minus,
    ignoreProps<CollapsibleProps>('isOpen')
)<CollapsibleProps>(({ isOpen }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    ...(!isOpen
        ? {
              opacity: 0,
              // Only hide the 'minus' icon after the 'plus' icon is fully visible
              transition: `opacity ${COLLAPSIBLE_ANIM_MS}ms`,
              transitionDelay: `${COLLAPSIBLE_ANIM_MS}ms`,
          }
        : {
              opacity: 1,
          }),
}));

const ModuleSectionsContainer = styled(Stack)({
    overflow: 'hidden',
    transition: `max-height ${COLLAPSIBLE_ANIM_MS}ms ease-out`,
    '& > :last-child': { borderBottom: 'none' }, // For last section
});

type ModuleProps<L extends Level, M extends ModuleType<L>> = {
    sectionsData: SectionData<L, M, SectionType<L, M>>[];
    moduleDefinition: ModuleDefinition<L, M>;
};

const Module = <L extends Level, M extends ModuleType<L>>({
    sectionsData: sections,
    moduleDefinition: { label, sections: sectionDefinitions },
}: ModuleProps<L, M>) => {
    const canHover = useCanHover();

    const [isOpen, setIsOpen] = useState(false); // TODO: Replace by atomWithStorage
    const [isAnimating, setIsAnimating] = useState(false);

    const [isChildrenOpen, setIsChildrenOpen] = useState(false); // TODO: Replace by atomWithStorage
    const [isChildrenAnimating, setIsChildrenAnimating] = useState(false);

    const contentRef = useRef<HTMLDivElement | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleOpen = () => {
        if (isAnimating) return; // Prevent animation glitches if already running

        setIsOpen((prev) => !prev);
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), COLLAPSIBLE_ANIM_MS);

        const contentEl = contentRef.current;
        const contentHeight = `${contentEl?.scrollHeight ?? 0}px`;

        if (!isOpen) {
            // While expanding, max-height is needed for the transition, but it can't
            // be there afterwards for the inner content to have a dynamic height
            contentEl?.style?.setProperty('max-height', contentHeight);
            setTimeout(() => contentEl?.style?.removeProperty('max-height'), COLLAPSIBLE_ANIM_MS);
        } else {
            // There must be an actual number for the transition to work,
            // so we have to set it and then quickly make it zero
            contentEl?.style?.setProperty('max-height', contentHeight);
            setTimeout(() => contentEl?.style?.setProperty('max-height', '0px'), 0);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChildrenOpen = () => {
        if (isChildrenAnimating) return; // Prevent animation glitches if already running

        setIsChildrenOpen((prev) => !prev);
        setIsChildrenAnimating(true);
        setTimeout(() => setIsChildrenAnimating(false), COLLAPSIBLE_ANIM_MS);
    };

    const handleLabelClick = () => {
        // Commenting this makes the module always start expanded:
        // handleOpen();

        // Commenting this makes each section be handled individually:
        handleChildrenOpen();
    };

    return (
        <ModuleContainer>
            <ModuleLabelContainer onClick={handleLabelClick} canHover={canHover}>
                <SingleLineTypography variant="subtitle2" fontWeight={700}>
                    {label}
                </SingleLineTypography>
                <ModuleIconContainer>
                    <ModulePlusIcon
                        size="24px"
                        weight="bold"
                        // Use each of these to make the icon follow...
                        // isOpen={isOpen} // ...the module state
                        isOpen={isChildrenOpen} // ...the children state
                    />
                    <ModuleMinusIcon
                        size="24px"
                        weight="bold"
                        // Use each of these to make the icon follow...
                        // isOpen={isOpen} // ...the module state
                        isOpen={isChildrenOpen} // ...the children state
                    />
                </ModuleIconContainer>
            </ModuleLabelContainer>
            <ModuleSectionsContainer
                ref={contentRef}
                // Commenting this makes the module always start expanded:
                // style={{ maxHeight: '0px' }} // Initial animation state, pure CSS, must be applied directly to the element
            >
                {sections.map((section) => (
                    <Section
                        key={section.type}
                        data={section.data}
                        sectionDefinition={sectionDefinitions[section.type]}
                        // Commenting this makes each section be handled individually:
                        overrideIsOpen={isChildrenOpen}
                        onLabelClick={() => !isChildrenOpen && handleChildrenOpen()}
                    />
                ))}
            </ModuleSectionsContainer>
        </ModuleContainer>
    );
};

export default Module;
