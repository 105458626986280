import { useAtom } from 'jotai';
import React from 'react';

import NewUnscheduledTaskDialog from '../../components/NewUnscheduledTaskDialog';
import { isDocumentationFlowFinalMessageOpenAtom } from '../atom';

import { FinalMessage } from './FinalMessage';

interface DocumentationFlowProps {
    isOpen: boolean;
    onClose: () => void;
    callId: number | null;
}

export const DocumentationFlow = ({ isOpen, callId, onClose }: DocumentationFlowProps) => {
    const [isDocumentationFlowFinalMessageOpen, toggleFinalMessage] = useAtom(isDocumentationFlowFinalMessageOpenAtom);

    const handleDocumentationFlowFinish = () => {
        onClose();
        toggleFinalMessage();
    };

    return (
        <>
            <NewUnscheduledTaskDialog
                isOpen={isOpen}
                onClose={handleDocumentationFlowFinish}
                callId={callId ?? undefined}
            />
            <FinalMessage
                isOpen={isDocumentationFlowFinalMessageOpen}
                onClose={toggleFinalMessage}
                isUnderFiveMinutes // TODO - make this dynamic
            />
        </>
    );
};
