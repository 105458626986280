import { Stack, Typography, alpha, styled } from '@mui/material';
import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataHorizontalSegmentedBar, {
    getSegmentedColorMapping,
} from '~/pages/OperationsV2/components/Module/Section/DataHorizontalSegmentedBar';
import DataLegend from '~/pages/OperationsV2/components/Module/Section/DataLegend';
import SeeMoreButton from '~/pages/OperationsV2/components/Module/SeeMore';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'REGION', 'CARE_REVENUE', 'DISTRIBUTION_OF_LEVELS'>[number];

const LocationNameContainer = styled(Stack)(({ theme: { palette } }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '8px',
    width: '100%',
    borderBottom: '1px solid',
    borderColor: palette.grey[500],
}));

const Dot = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: alpha(palette.grey[900], 0.2),
    width: '8px',
    height: '8px',
    borderRadius: '50%',
}));

const Item = ({
    item,
    itemIndex,
    selectedItemIndex,
    setSelectedItemIndex,
}: {
    item: Item;
    itemIndex: number;
    selectedItemIndex: number;
    setSelectedItemIndex: (value: number) => void;
}) => {
    const isSelected = itemIndex === selectedItemIndex;

    return (
        <Stack
            key={itemIndex}
            onClick={() => setSelectedItemIndex(itemIndex)}
            sx={{
                position: 'relative',
                gap: '4px',
                pr: '16px',
                cursor: 'pointer',
            }}
        >
            <Typography variant="body2" fontWeight={isSelected ? 700 : 500}>
                {item.locationName}
            </Typography>
            <DataHorizontalSegmentedBar
                variant={isSelected ? 'selected' : 'unselected'}
                items={item.levelItems.map(({ count }) => ({ weight: count }))}
                sx={{ height: '16px' }}
            />
            {isSelected && <Dot sx={{ position: 'absolute', right: 0, bottom: 4 }} />}
        </Stack>
    );
};

const ChartContainer = styled(Stack)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100] as string,
    gap: '20px',
    padding: '24px',
    width: '100%',
    borderRadius: '16px',
}));

const Data = ({ items }: { items: Item[] }) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const selectedItem = items[selectedItemIndex];

    const [isSeeAll, setIsSeeAll] = useState(false);
    const noOfItemsToShow = isSeeAll ? items.length : 4;

    const legendColorMapping = useMemo(
        () => getSegmentedColorMapping(selectedItem.levelItems.length).selected,
        [selectedItem]
    );

    return (
        <DataContainer sx={{ alignItems: 'flex-start', gap: '12px' }}>
            <LocationNameContainer>
                <Typography>{selectedItem.locationName}</Typography>
            </LocationNameContainer>
            <DataLegend
                rows={selectedItem.levelItems.slice(0, noOfItemsToShow).map((levelItem, levelIndex) => ({
                    label: `Level ${levelIndex + 1}`,
                    values: [`${formatPercentage(levelItem.percentage)} (${levelItem.count} residents)`],
                }))}
                colorMapping={legendColorMapping}
                containerProps={{ width: '100%', pt: '4px', pb: '8px' }}
            />
            <SeeMoreButton
                see={isSeeAll ? 'less' : 'more'}
                onClick={() => setIsSeeAll((prev) => !prev)}
                sx={{ mb: '12px' }}
            />
            <ChartContainer>
                {items.map((item, itemIndex) => (
                    <Item
                        key={itemIndex}
                        item={item}
                        itemIndex={itemIndex}
                        selectedItemIndex={selectedItemIndex}
                        setSelectedItemIndex={setSelectedItemIndex}
                    />
                ))}
            </ChartContainer>
        </DataContainer>
    );
};

export default Data;
