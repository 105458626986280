import AvgResponseTime from './AvgResponseTime';
import DocumentationRate from './DocumentationRate';
import Incidents from './Incidents';
import LongestResponseTime from './LongestResponseTime';
import Medication from './Medication';
import OverdueAssessmentsAndIncompleteFalls from './OverdueAssessmentsAndIncompleteFalls';
import ResidentsWithNeedsChanges from './ResidentsWithNeedsChanges';
import TotalCalls from './TotalCalls';
import Transfers from './Transfers';

export default {
    AvgResponseTime,
    LongestResponseTime,
    ResidentsWithNeedsChanges,
    TotalCalls,
    Incidents,
    Medication,
    Transfers,
    DocumentationRate,
    OverdueAssessmentsAndIncompleteFalls,
};
