import { Box, Button, IconButton, Modal, styled, useTheme } from '@mui/material';
import { X } from '@phosphor-icons/react';
import React, { ComponentProps, ReactNode } from 'react';

const CustomDrawerContainer = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
}));

const CustomDrawerContent = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    position: 'relative',
    width: '100%',
    height: '80%',

    [theme.breakpoints.up('md')]: {
        top: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        maxWidth: '570px',
    },
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '8px',
    padding: '16px 24px',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    alignItems: 'center',
}));

const CloseButton = ({ sx, ...props }: ComponentProps<typeof Button>) => {
    const { palette } = useTheme();

    return (
        <IconButton
            size="small"
            sx={{
                position: 'absolute',
                top: '16px',
                right: '24px',
                minWidth: '32px',
                height: '32px',
                p: 0,
                borderRadius: '50%',
                opacity: props.disabled ? 0.5 : 1,
                ...sx,
            }}
            {...props}
        >
            <X size={24} color={palette.grey[600]} weight="bold" />
        </IconButton>
    );
};

const CustomDrawer = ({
    isOpen,
    onClose,
    header,
    closeButton,
    closeButtonProps,
    children,
    fullScreen,
    ...props
}: {
    isOpen: boolean;
    onClose: () => void;
    header?: ReactNode;
    closeButton?: true;
    fullScreen?: boolean;
    closeButtonProps?: ComponentProps<typeof Button>;
    children: ReactNode;
} & ComponentProps<typeof CustomDrawerContent>) => {
    return (
        <Modal open={isOpen} onClose={onClose}>
            <CustomDrawerContainer>
                <CustomDrawerContent {...props} sx={fullScreen ? { ...(props.sx ?? {}), height: '100%' } : props.sx}>
                    {closeButton && <CloseButton onClick={onClose} {...closeButtonProps} />}
                    {header && <Header>{header}</Header>}
                    {children}
                </CustomDrawerContent>
            </CustomDrawerContainer>
        </Modal>
    );
};

export default CustomDrawer;
