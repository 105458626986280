import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Data = DetailsGroupingDataType<'REGION', 'CARE_REVENUE', 'RESIDENT_CHANGES', 'POTENTIAL_CARE_LEVEL_INCREASE'>;

const Data = ({ data: { regionItems, communityItems } }: { data: Data }) => {
    return (
        <DataContainer>
            <DataTable
                containerProps={{ sx: { marginBottom: '24px', minWidth: '100%', overflowX: 'auto' } }}
                columns={{
                    regionName: {
                        label: 'Region',
                        allowSort: false,
                        weight: 1,
                    },
                    residentCount: {
                        label: 'Residents Count',
                        allowSort: false,
                        weight: 1,
                    },
                }}
                rows={regionItems}
            />
            <DataTable
                containerProps={{ sx: { maxWidth: '100%', overflowX: 'auto' } }}
                columns={{
                    residentName: {
                        label: 'Resident',
                        renderCell: (value, { row }) => (
                            <Stack sx={{ gap: '2px' }}>
                                <Typography>{value}</Typography>
                                <Typography variant="caption">{row.branchName}</Typography>
                            </Stack>
                        ),
                    },
                    callCount: { label: 'Call Count\n(current)' },
                    avgTimeSpentPerShiftInMs: {
                        label: 'Avg. Time Spent\nper shift',
                        renderCell: (value) => formatMsToMinutes(value),
                    },
                    careLevel: { label: 'Care Level /\nAssessment Points' },
                    upcomingAssessmentDate: {
                        label: 'Upcoming\nAssignment',
                        renderCell: (value) => DateTime.fromISO(value).toFormat('MMM d'),
                    },
                    allieHealthCoCScore: { label: 'AllieHealth CoC\nScore / Alert' },
                    allieHealthNotes: {
                        label: 'AllieHealth Notes',
                        renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : '-'),
                    },
                }}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
