import ChangeOfCondition from './ChangeOfCondition';
import DistributionOfLevels from './DistributionOfLevels';
import OnDemandCare from './OnDemandCare';
import ResidentChanges from './ResidentChanges';

export default {
    ResidentChanges,
    OnDemandCare,
    ChangeOfCondition,
    DistributionOfLevels,
};
