import CareRevenue from './CareRevenue';
import Labor from './Labor';
import RiskAndCompliance from './RiskAndCompliance';

export default {
    modules: {
        RiskAndCompliance,
        Labor,
        CareRevenue,
    },
};
