import { Duration } from 'luxon';
import pluralize from 'pluralize';

/**
 * Format a time difference in hours as a string in the
 * format "d'd' h'h' m'm" (e.g. "1d 2h 30m")
 */
export function formatTimeDiff(timeDiffInHours: number) {
    const days = Math.floor(timeDiffInHours / 24);
    const hours = timeDiffInHours % 24;
    const minutes = Math.floor((hours % 1) * 60);

    let formatString = '';
    // If the time is less than 1 day, only show hours and minutes
    // otherwise, show only days and hours
    if (days > 0) {
        formatString = `${days >= 1 ? `d '${pluralize('day', days)}' ` : ''}${hours >= 1 ? `h 'hr' ` : ''}`.trim();
    } else if (timeDiffInHours < 1) {
        formatString = "m 'min'";
    } else {
        formatString = `${hours >= 1 ? `h 'hr' ` : ''}${minutes >= 1 ? `m 'min' ` : ''}`.trim();
    }

    return Duration.fromObject({ hours: timeDiffInHours }).toFormat(formatString);
}
