import { Box, Theme, styled, useMediaQuery } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ShiftOption } from '@allie/utils/src/shifts';

import { useGetAssignments } from '~/api/queries/assignments/getAssignments';
import { useGetCareLocations } from '~/api/queries/assignments/getCareLocations';
import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';
import { useGetZones } from '~/api/queries/zones/getZones';
import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { usePermissions } from '~/permissions/utils';
import { DailyTasksByTabStrict, TabKey } from '~/types/dailyTasks.d';
import { ReduxStore } from '~/types/redux';

import {
    isFiltersExpandedAtom,
    isLocationSelectorDialogOpenAtom,
    isShiftSelectorDialogOpenAtom,
    isSortDialogOpenAtom,
    selectedAssignmentIdsAtom,
    selectedDateAtom,
} from '../../atom';
import { isShiftInDate, shouldUseAssignments } from '../../helpers';
import { useShiftStartDay } from '../../useShiftStartDay';
import { TabSelector } from '../TabSelector';

import HeaderChip from './components/HeaderChip';
import { SortButton } from './components/SortButton';
import DatePicker from './components/filters/date/DatePicker';

const MAX_DAYS_TO_SHOW = 3;

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary[500],

    [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.common.white,
    },
}));

const Content = styled(Box)(({ theme }) => ({
    padding: '12px 16px 8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
    },
}));

const HeaderRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
}));

const FiltersContainer = styled(Box)<{ showFilters: boolean }>(({ theme, showFilters }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',
    maxHeight: showFilters ? '124px' : '0px',
    overflow: 'hidden',
    transition: 'max-height 300ms ease-in-out',

    [theme.breakpoints.up('sm')]: {
        gap: '16px',
    },
}));

type HeaderV2Props = {
    selectedTab: TabKey;
    tasksByTab: DailyTasksByTabStrict;
    onTabChange: (tab: TabKey) => void;
    onShiftChange: (shiftId: number) => void;
    selectedShiftDay: string;
    currentShiftDayDate: string;
    selectedShiftId: number;
    nearbyShifts: ShiftOption[];
    currentShiftId: number;
    selectedBranchId?: number;
};

export default function HeaderV2({
    selectedTab,
    tasksByTab,
    onTabChange,
    onShiftChange,
    selectedShiftDay,
    currentShiftDayDate,
    nearbyShifts,
    selectedShiftId,
    currentShiftId,
    selectedBranchId,
}: HeaderV2Props) {
    const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm'));
    const hasPermission = usePermissions();

    // TODO - get rid of this and use atoms
    const { company, branch } = useSelector((state: ReduxStore) => state.session.sessionData);

    const isLiveCallsTab = selectedTab === TabKey.LIVE_CALLS && !!hasPermission('Community', 'ecall-caregiver');
    const shouldGetZonesAndAssignments = !isLiveCallsTab && !!selectedShiftId && !!selectedShiftDay;

    const shiftDay = useShiftStartDay(selectedShiftDay, selectedShiftId);
    const { data: zones } = useGetZones({
        enabled: shouldGetZonesAndAssignments,
        shiftId: selectedShiftId,
        shiftDay,
        branchId: selectedBranchId,
    });
    const { data: assignments } = useGetAssignments({
        enabled: shouldGetZonesAndAssignments,
        shiftId: selectedShiftId,
        shiftDay,
        branchId: selectedBranchId,
    });
    const { data: careLocations } = useGetCareLocations(isLiveCallsTab);
    const { data: careLocationsIds } = useGetChosenCareLocations(isLiveCallsTab);

    const locations = useMemo(() => {
        if (selectedTab === TabKey.LIVE_CALLS) {
            return careLocations;
        }

        return shouldUseAssignments(company, branch)
            ? assignments?.map((assignment) => ({ id: assignment.assignmentId, name: assignment.assignmentName }))
            : zones?.map((zone) => ({ id: zone.zoneId, name: zone.zoneName }));
    }, [company, branch, assignments, selectedTab, careLocations, zones]);

    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);
    const [assignmentIds, setAssignmentIds] = useAtom(selectedAssignmentIdsAtom);
    const toggleShiftSelectorDialog = useSetAtom(isShiftSelectorDialogOpenAtom);
    const toggleLocationSelectorDialog = useSetAtom(isLocationSelectorDialogOpenAtom);
    const toggleSortDialog = useSetAtom(isSortDialogOpenAtom);
    const isFiltersExpanded = useAtomValue(isFiltersExpandedAtom);
    const showFilters = !isMobile || isFiltersExpanded;

    const currentShiftDateTzDateTime = DateTime.fromISO(currentShiftDayDate);

    useEffect(() => {
        // this ensures that if the storage assignmentIds are not in the locations,
        // we reset them setting to ALL_ASSIGNMENTS_ID
        const locationsIds = locations?.map(({ id }) => id) ?? [];
        if (locations && assignmentIds?.some((id) => !locationsIds.includes(id))) {
            setAssignmentIds([ALL_ASSIGNMENTS_ID]);
        }
    }, [locations]);

    const handleDateChange = (newDate: DateTime<true> | null) => {
        if (newDate) {
            setSelectedDate(newDate);

            // changing to the current shift when the date changes to
            // today to avoid selecting shifts too far in the future
            if (newDate.hasSame(DateTime.now(), 'day')) {
                onShiftChange(currentShiftId);
            }
        }
    };

    const selectedShiftsLabel = useMemo(() => {
        const selectedShiftName = nearbyShifts.find((shift) => shift.id === selectedShiftId)?.name;
        const [previousShift, currentShift, nextShift] = nearbyShifts;

        let selectedShiftTab = '';

        if (isShiftInDate(currentShift, selectedDate)) {
            if (selectedShiftId === currentShift.id) selectedShiftTab = '(current)';
            if (selectedShiftId === previousShift.id) selectedShiftTab = '(previous)';
            if (selectedShiftId === nextShift.id) selectedShiftTab = '(next)';
        }

        return selectedShiftName ? `${selectedShiftName} ${selectedShiftTab}` : '';
    }, [currentShiftId, nearbyShifts, selectedShiftId]);

    const selectedLocationsLabel = useMemo(() => {
        // deciding which assignmentIds to use
        // live calls: the care locations to receive ecall notifications
        // other tabs: simple location filter
        const selectedAssignmentIds = selectedTab === TabKey.LIVE_CALLS ? careLocationsIds : assignmentIds;

        if (selectedAssignmentIds?.includes(ALL_ASSIGNMENTS_ID)) {
            return locations?.map(({ name }) => name).join(', ') || 'No assignments';
        }

        const zonesNames = locations?.filter(({ id }) => selectedAssignmentIds?.includes(id)) ?? [];

        return zonesNames.map(({ name }) => name).join(', ') || 'No assignments';
    }, [locations, assignmentIds, careLocationsIds, selectedTab]);

    return (
        <Container>
            <Content>
                <HeaderRow>
                    <DatePicker
                        selectedDate={selectedDate}
                        handleDateChange={handleDateChange}
                        minDate={
                            hasPermission('Community', 'read-all-resident-actions')
                                ? undefined
                                : DateTime.now().minus({ days: MAX_DAYS_TO_SHOW })
                        }
                        maxDate={currentShiftDateTzDateTime.isValid ? currentShiftDateTzDateTime : DateTime.now()}
                        hideArrows={selectedTab === TabKey.LIVE_CALLS}
                        enabled={selectedTab !== TabKey.LIVE_CALLS}
                    />
                </HeaderRow>
                <FiltersContainer showFilters={showFilters}>
                    <HeaderRow order={1}>
                        <HeaderChip
                            title="Shift"
                            label={selectedShiftsLabel}
                            isDisabled={selectedTab === TabKey.LIVE_CALLS}
                            onClick={toggleShiftSelectorDialog}
                        />
                    </HeaderRow>
                    <Box
                        order={isMobile ? 3 : 2}
                        sx={{ width: { xs: '100%' }, maxWidth: { sm: '210px', md: '350px' } }}
                    >
                        <HeaderRow width="fit-content">
                            <HeaderChip
                                title={selectedTab === TabKey.LIVE_CALLS ? 'Call Location' : 'Location'}
                                label={selectedLocationsLabel}
                                onClick={toggleLocationSelectorDialog}
                            />
                        </HeaderRow>
                    </Box>
                    <HeaderRow order={isMobile ? 2 : 3} marginLeft="auto">
                        <SortButton onClick={toggleSortDialog} />
                    </HeaderRow>
                </FiltersContainer>
            </Content>
            <TabSelector
                tabs={tasksByTab}
                selectedTab={selectedTab}
                onTabChange={onTabChange}
                shiftDay={selectedDate ?? undefined}
                shiftId={selectedShiftId}
            />
        </Container>
    );
}
