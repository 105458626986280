import { InputAdornment, TextField, useTheme } from '@mui/material';
import React from 'react';
import { PiMagnifyingGlass, PiX } from 'react-icons/pi';

import { usePermissions } from '~/permissions/utils';

interface SearchFieldProps {
    searchQuery: string;
    setSearchQuery: (query: string) => void;
}

export const SearchField = ({ searchQuery, setSearchQuery }: SearchFieldProps) => {
    const { palette } = useTheme();
    const hasPermission = usePermissions();

    // TODO - check if is manager
    const isCaregiver = hasPermission('Community', 'ecall-caregiver');

    return (
        <TextField
            fullWidth
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={isCaregiver ? 'Type a Resident name' : 'Type a Resident or Staff name'}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {<PiMagnifyingGlass size={24} color={palette.grey[900]} />}
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" onClick={() => setSearchQuery('')} sx={{ cursor: 'pointer' }}>
                        {searchQuery && <PiX size={24} color={palette.grey[900]} />}
                    </InputAdornment>
                ),
            }}
        />
    );
};
