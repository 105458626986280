import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';

type Data = DetailsGroupingDataType<'REGION', 'RISK_AND_COMPLIANCE', 'INCIDENTS', 'ALL'>;

const Data = ({ data: { regionItems, communityItems } }: { data: Data }) => {
    return (
        <DataContainer>
            <DataTable
                containerProps={{ sx: { marginBottom: '24px', width: '100%' } }}
                columns={{
                    regionName: { label: 'Region', allowSort: false, weight: 1 },
                    fallCount: { label: 'Fall', allowSort: false, weight: 1 },
                    onFloorCount: { label: 'On Floor', allowSort: false, weight: 1 },
                    altercationCount: { label: 'Altercation', allowSort: false, weight: 1 },
                }}
                rows={regionItems}
            />
            <DataTable
                containerProps={{ sx: { maxWidth: '100%', overflowX: 'scroll' } }}
                columns={{
                    branchName: { label: 'Community' },
                    fallCount: { label: 'Fall' },
                    onFloorCount: { label: 'On Floor' },
                    altercationCount: { label: 'Altercation' },
                }}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
