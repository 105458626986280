import Emergency from './Emergency';
import NotFeelingWell from './NotFeelingWell';
import Other from './Other';

export default {
    groupings: {
        Emergency,
        NotFeelingWell,
        Other,
    },
};
