import { Typography } from '@mui/material';
import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';

type Item = SectionDataType<'REGION', 'LABOR', 'HIRING_NEEDS'>['items'][number];

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer position="relative">
        <DataTable
            columns={{
                branchName: {
                    label: 'Community',
                    weight: 1.5,
                },
                hiresNeededCount: {
                    label: 'FT Hires Needed*',
                    alignment: 'right',
                },
            }}
            rows={items}
            takeFirst={3}
            seeMore={() => {}}
            containerProps={{ width: '100%' }}
        />
        <Typography
            variant="body2"
            sx={{
                position: 'absolute',
                bottom: '44px',
                right: '48px',
            }}
        >
            *Estimated
        </Typography>
    </DataContainer>
);

export default Data;
