import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import { NetworkProvider } from '~/contexts/NetworkContext';
import { isPostHogEnabled } from '~/helpers/PostHogHandler';
import '~/polyfills';
import Store from '~/redux/Store';
import Root from '~/root';

import reportWebVitals from './reportWebVitals';

const SENTRY_PROJECT_ID = '4505177530957824';
const SENTRY_ORGANIZATION_ID = 'allie-2';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: `https://5c38483ccd4b4510868932aa160cd3c2@o4505177516736512.ingest.sentry.io/${SENTRY_PROJECT_ID}`,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.05,
        integrations: [
            Sentry.browserTracingIntegration(),
            new posthog.SentryIntegration(posthog, SENTRY_ORGANIZATION_ID, SENTRY_PROJECT_ID, undefined, '*'),
        ],
    });
}

if (isPostHogEnabled) {
    posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        ui_host: 'https://us.posthog.com', // required with reverse proxy
        opt_in_site_apps: true,
        feature_flag_request_timeout_ms: 0,
        loaded: () => {
            Store.dispatch({
                type: 'SESSION_POSTHOG_LOADED',
                payload: true,
            });
        },
        session_recording: {
            maskAllInputs: false,
        },
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store}>
        <PostHogProvider client={posthog}>
            <IntercomProvider appId="ydfuumfb" autoBoot>
                <NetworkProvider>
                    <Root />
                </NetworkProvider>
            </IntercomProvider>
        </PostHogProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
