import { Typography } from '@mui/material';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

type Items = DetailsGroupingDataType<'REGION', 'RISK_AND_COMPLIANCE', 'DOCUMENTATION_RATE', 'ALL'>;

const Data = ({ items }: { items: Items }) => {
    return (
        <DataContainer>
            <DataTable
                columns={{
                    branchName: { label: 'Community' },
                    scheduledDocumentationCount: { label: 'Scheduled\nDocumentation' },
                    scheduledNotDocumentedChangePercentage: {
                        label: 'Scheduled\nTasks Not\nDocumented',
                        renderCell: (scheduledNotDocumentedChangePercentage, { row }) => (
                            <Typography>
                                {formatPercentage(scheduledNotDocumentedChangePercentage, {
                                    showSign: 'never',
                                })}{' '}
                                ({row.scheduledNotDocumentedCount})
                            </Typography>
                        ),
                    },
                    ecallDocumentationPercentage: {
                        label: 'E-Call\nDocumentation',
                        renderCell: (ecallDocumentationPercentage) =>
                            formatPercentage(ecallDocumentationPercentage, { showSign: 'never' }),
                    },
                    totalCallsCount: { label: 'Total Calls' },
                }}
                rows={items}
            />
        </DataContainer>
    );
};

export default Data;
