import { alpha, styled } from '@mui/material';
import React from 'react';

import { DotProps, Item } from '~/pages/OperationsV2/utils/recharts';

const IndicatorLine = styled('line')(({ theme: { palette } }) => ({
    stroke: alpha(palette.grey[900], 0.1),
    strokeWidth: '2px',
}));

type DataMultiLineChartActiveDotProps<T extends Item, TX extends keyof T> = {
    chartHeight: number;
    dataKey: TX;
    keyIndex: number;
    color: string;
    isSelected: (item: T) => boolean;
    isFocused: (item: T) => boolean;
};

const DataMultiLineChartActiveDot = <T extends Item, TX extends keyof T>({
    cx,
    cy,
    payload: item,
    chartHeight,
    dataKey,
    keyIndex,
    color,
    isFocused: isFocusedFn,
}: DotProps<T> & DataMultiLineChartActiveDotProps<T, TX>) => {
    if (!item[dataKey]) return null;

    const isFocused = isFocusedFn(item);

    if (isFocused) return null;

    return (
        <>
            {/* We want to render the indicator line before any
            dot and a single time so it doesn't overlap them */}
            {keyIndex === 0 && (
                <IndicatorLine
                    x1={cx}
                    x2={cx}
                    y1={0}
                    y2={chartHeight - 40} // Subtract label/caption height
                />
            )}
            <circle cx={cx} cy={cy} r={6} fill={color} opacity={0.25} />
            <circle cx={cx} cy={cy} r={4} fill="white" opacity={0.5} />
        </>
    );
};

export default DataMultiLineChartActiveDot;
