import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

export const DataWithChangePercentage = ({ value, percentageChange }: { value: number; percentageChange: number }) => {
    const { palette } = useTheme();

    return (
        <Stack direction="row" alignItems="center" gap="8px">
            <SingleLineTypography>{value}</SingleLineTypography>
            <Typography color={percentageChange > 0 ? (palette.error[700] as string) : undefined}>
                ({formatPercentage(percentageChange, { showSign: 'always' })})
            </Typography>
        </Stack>
    );
};
