import { IconButton, Stack, Typography, styled, useTheme } from '@mui/material';
import { alpha } from '@mui/system';
import { ArrowRight } from '@phosphor-icons/react';
import { useSetAtom } from 'jotai';
import React, { MouseEvent, useRef, useState } from 'react';

import { ModuleType, SectionDataType, SectionType } from '@allie/operations-common/src/types/module';
import { Level } from '@allie/operations-common/src/types/shared';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useCanHover } from '~/hooks/useCanHover';
import { detailsPayloadAtom } from '~/pages/OperationsV2/atoms';
import { SectionDefinition } from '~/pages/OperationsV2/modules';
import { ignoreProps } from '~/pages/OperationsV2/utils/styled';

import { COLLAPSIBLE_ANIM_MS, CollapsibleProps, HoverableProps } from '../shared';

const SectionContainer = styled(Stack)(({ theme: { palette } }) => ({
    // Using border instead of Stack's divider because of height calculation for hidden elements
    borderBottom: '1px solid',
    borderColor: alpha(palette.grey[900], 0.1),
}));

type SectionLabelContainerProps = {
    hasDetails: boolean;
} & HoverableProps &
    CollapsibleProps;

const SectionLabelContainer = styled(
    Stack,
    ignoreProps<SectionLabelContainerProps>('hasDetails', 'canHover', 'isOpen')
)<SectionLabelContainerProps>(({ theme: { palette }, hasDetails, canHover, isOpen }) => ({
    color: palette.grey[900],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '24px',
    paddingRight: !hasDetails || !isOpen ? '24px' : '16px', // For the 'details' button inner padding
    gap: '8px',
    cursor: 'pointer',
    userSelect: 'none',
    transition: `background-color 0.1s, padding-right ${COLLAPSIBLE_ANIM_MS}ms`,
    // Avoid hover on children affecting parent
    '&:hover:not(:has(button:hover))': canHover && { backgroundColor: alpha(palette.grey[900], 0.05) },
    '&:active:not(:has(button:hover))': { backgroundColor: alpha(palette.grey[900], 0.1) },
}));

const SectionCaption = styled(Typography)(({ theme: { palette } }) => ({
    color: alpha(palette.grey[900], 0.7),
    textWrap: 'balance',
}));

const SectionSummaryContainer = styled(Stack)({
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
});

const SectionDetailsButton = styled(
    IconButton,
    ignoreProps<CollapsibleProps>('isOpen')
)<CollapsibleProps>(({ isOpen }) => ({
    width: `${isOpen ? 40 : 0}px`,
    height: '40px',
    marginLeft: `${isOpen ? 4 : 0}px`,
    padding: 0,
    opacity: isOpen ? 1 : 0,
    transition: `all ${COLLAPSIBLE_ANIM_MS}ms`,
}));

type SectionDataContainerProps = { el: HTMLDivElement | null } & CollapsibleProps;

const SectionDataContainer = styled(
    Stack,
    ignoreProps<SectionDataContainerProps>('el', 'isOpen')
)<SectionDataContainerProps>(({ el, isOpen }) => ({
    alignItems: 'center',
    overflow: 'hidden',
    maxHeight: `${isOpen ? (el?.scrollHeight ?? 0) : 0}px`,
    transition: `max-height ${COLLAPSIBLE_ANIM_MS}ms ease-out`,
}));

type SectionProps<L extends Level, M extends ModuleType<L>, S extends SectionType<L, M>> = {
    data: SectionDataType<L, M, S>;
    sectionDefinition: SectionDefinition<L, M, S>;
    overrideIsOpen?: boolean;
    onLabelClick?: () => void;
};

const Section = <L extends Level, M extends ModuleType<L>, S extends SectionType<L, M>>({
    data,
    sectionDefinition: { label, caption, summary, detailsPayload, renderData },
    overrideIsOpen,
    onLabelClick,
}: SectionProps<L, M, S>) => {
    const { palette } = useTheme();

    const canHover = useCanHover();

    const [isOpen, setIsOpen] = useState(false); // TODO: Replace by atomWithStorage
    const setDetailsPayload = useSetAtom(detailsPayloadAtom);

    const labelRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const handleLabelClick = () => {
        onLabelClick?.();

        if (overrideIsOpen === undefined) setIsOpen((prev) => !prev);

        // WIP: This simulates requestAnimationFrame() at a lower framerate and
        // with an easier calculation to keep scrolling in sync with the expand
        // animation, as the label is constantly changing position on screen
        for (
            let i = 0;
            i < 2 * COLLAPSIBLE_ANIM_MS; // Account for 2 concurrent animations during expansion
            i += 10 // 1000ms / 10ms = 100fps at most
        ) {
            setTimeout(() => {
                labelRef.current?.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }, i);
        }
    };

    const handleGoToDetailsClick = (e: MouseEvent) => {
        e.stopPropagation();

        if (detailsPayload) setDetailsPayload(detailsPayload);
    };

    const renderedCaption = typeof caption === 'function' ? caption(data, { palette }) : caption;
    const renderedSummary = typeof summary === 'function' ? summary(data, { palette }) : summary;

    return (
        <SectionContainer>
            <SectionLabelContainer
                ref={labelRef}
                onClick={handleLabelClick}
                hasDetails={!!detailsPayload}
                canHover={canHover}
                isOpen={overrideIsOpen ?? isOpen}
            >
                <Stack py="20px" spacing="4px">
                    {/* Only allow text wrapping if there's no caption, so
                    the section label container doesn't grow too tall */}
                    {renderedCaption ? (
                        <>
                            <SingleLineTypography fontWeight={700}>{label}</SingleLineTypography>
                            <SectionCaption variant="caption">{renderedCaption}</SectionCaption>
                        </>
                    ) : (
                        <Typography fontWeight={700} sx={{ textWrap: 'balance' }}>
                            {label}
                        </Typography>
                    )}
                </Stack>
                <SectionSummaryContainer>
                    {renderedSummary && <SingleLineTypography fontWeight={700}>{renderedSummary}</SingleLineTypography>}
                    {detailsPayload && (
                        <SectionDetailsButton
                            color="inherit"
                            onClick={handleGoToDetailsClick}
                            isOpen={overrideIsOpen ?? isOpen}
                        >
                            <ArrowRight size="24px" />
                        </SectionDetailsButton>
                    )}
                </SectionSummaryContainer>
            </SectionLabelContainer>
            <SectionDataContainer ref={contentRef} isOpen={overrideIsOpen ?? isOpen} el={contentRef.current}>
                {renderData(data, { palette })}
            </SectionDataContainer>
        </SectionContainer>
    );
};

export default Section;
