import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { useResidentPhoto } from '~/api/queries/media';
import { AVATAR_WIDTH } from '~/constants/residents';
import { getInitials } from '~/lib/common';

type Props = {
    backgroundColor?: string;
    fontColor?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    photo: string | null;
    size?: number;
    sx?: object;
};

// TODO: Do not use this for caregivers (AH-950) and rename to ResidentAvatar
export const CustomAvatar = (props: Props) => {
    const { palette } = useTheme();
    const {
        backgroundColor = palette.primary[300] as string,
        fontColor = palette.common.white,
        firstName,
        lastName,
        photo,
        sx,
        size = AVATAR_WIDTH,
    } = props;
    const fullName = props.fullName ?? `${firstName} ${lastName}`;
    const initials = getInitials(firstName, lastName);
    const { data: imgURL } = useResidentPhoto(photo);

    const avatarStyle = {
        color: fontColor,
        backgroundColor,
        width: size + 'px',
        height: size + 'px',
        fontSize: size * 0.5 + 'px',
        ...sx,
    };

    return (
        <Avatar alt={fullName} src={imgURL} sx={avatarStyle}>
            {initials}
        </Avatar>
    );
};
