import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';

type Data = DetailsGroupingDataType<'REGION', 'RISK_AND_COMPLIANCE', 'TRANSFERS', 'ALL'>;

const Data = ({ data: { regionItems, communityItems } }: { data: Data }) => {
    return (
        <DataContainer>
            <DataTable
                containerProps={{ sx: { width: '100%', marginBottom: '24px' } }}
                columns={{
                    regionName: { label: 'Region', allowSort: false, weight: 1 },
                    nineOneOneCount: { label: '911', allowSort: false, weight: 1 },
                    medicalCount: { label: 'Medical', allowSort: false, weight: 1 },
                }}
                rows={regionItems}
            />
            <DataTable
                containerProps={{ sx: { width: '100%', overflowX: 'auto' } }}
                columns={{
                    branchName: { label: 'Community', weight: 1 },
                    nineOneOneCount: { label: '911', weight: 1 },
                    medicalCount: { label: 'Medical', weight: 1 },
                }}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
